import React, { lazy } from 'react';

const OV50_VSLQS_V1 = lazy(() => import('./Components/Pages/OV50_VSLQS_V1/OV50_VSLQS_V1'));
const OV50_VSLQS_V1_1 = lazy(() => import('./Components/Pages/OV50_VSLQS_V1_1/OV50_VSLQS_V1_1'));
const OV50_CHBQS_V2 = lazy(() => import('./Components/Pages/OV50_CHBQS_V2/OV50_CHBQS_V2'));
const OV50_CHBQS_V2_1 = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_1/OV50_CHBQS_V2_1'));
const OV50_GPOS_V3 = lazy(() => import('./Components/Pages/OV50_GPOS_V3/OV50_GPOS_V3'));
const OV50_GPOS_V3_5 = lazy(() => import('./Components/Pages/OV50_GPOS_V3_5/OV50_GPOS_V3_5'));
const OV50_CHBQS_V2_5 = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_5/OV50_CHBQS_V2_5'));
const OV50_VSLQS_V1_2 = lazy(() => import('./Components/Pages/OV50_VSLQS_V1_2/OV50_VSLQS_V1_2'));
const OV50_VSLQS_V1_3 = lazy(() => import('./Components/Pages/OV50_VSLQS_V1_3/OV50_VSLQS_V1_3'));
const OV50_GPOS_V3_6 = lazy(() => import('./Components/Pages/OV50_GPOS_V3_6/OV50_GPOS_V3_6'));
const OV50_GPOS_V3_7 = lazy(() => import('./Components/Pages/OV50_GPOS_V3_7/OV50_GPOS_V3_7'));
const OV50_CHBQS_V2_6 = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_6/OV50_CHBQS_V2_6'));
const OV50_CHBQS_V2_7 = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_7/OV50_CHBQS_V2_7'));
const OV50_CHBQS_V2_7_1 = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_7_1/OV50_CHBQS_V2_7_1'));
const OV50_CHBQS_V2_5B = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_5B/OV50_CHBQS_V2_5B'));
const OV50_CHBQS_V2_7_1B = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_7_1B/OV50_CHBQS_V2_7_1B'));
const QSCV_ADV1 = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV1'));
const QSCV_ADV2  = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV2'));
const QSCV_ADV3  = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV3'));
const QSCV_ADV5  = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV5'));
const QSCV_ADV4  = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV4'));
const QSCV_ADV2B  = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV2B'));
const QSCV_ADV2D  = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV2D'));
const QSCV_ADV2C  = lazy(() => import('./Components/Pages/Advertorial/QSCV_ADV2C'));
const OV50_CHBQS_V2_7_3 = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_7_3/OV50_CHBQS_V2_7_3'));
const FB_ADV1 = lazy(() => import('./Components/Pages/Advertorial/FB_ADV1'));
const OV50_CHBQS_V2_7_2D = lazy(() => import('./Components/Pages/OV50_CHBQS_V2_7_2D/OV50_CHBQS_V2_7_2D'));


const AppSplitList = (props) => {

    const SplitListMap = {
        'OV50_VSLQS_V1': OV50_VSLQS_V1,
        'OV50_CHBQS_V2': OV50_CHBQS_V2,
        'OV50_VSLQS_V1_1': OV50_VSLQS_V1_1,
        'OV50_CHBQS_V2_1': OV50_CHBQS_V2_1,
        'OV50_GPOS_V3': OV50_GPOS_V3,
        'OV50_GPOS_V3_5': OV50_GPOS_V3_5,
        'OV50_CHBQS_V2_5': OV50_CHBQS_V2_5,
        "OV50_VSLQS_V1":OV50_VSLQS_V1,
        "OV50_VSLQS_V1_2":OV50_VSLQS_V1_2,
        "OV50_VSLQS_V1_3":OV50_VSLQS_V1_3,
        "OV50_GPOS_V3_6":OV50_GPOS_V3_6,
        "OV50_GPOS_V3_7":OV50_GPOS_V3_7,
        "OV50_CHBQS_V2_6":OV50_CHBQS_V2_6,
        "OV50_CHBQS_V2_7":OV50_CHBQS_V2_7,
        "OV50_CHBQS_V2_7_1":OV50_CHBQS_V2_7_1,
        "OV50_CHBQS_V2_5B":OV50_CHBQS_V2_5B,
        "OV50_CHBQS_V2_7_1B":OV50_CHBQS_V2_7_1B,
        "Over50/QSCV_ADV1":QSCV_ADV1,
        "Over50/QSCV_ADV1":QSCV_ADV1,
        "Over50/QSCV_ADV2":QSCV_ADV2,
        "Over50/QSCV_ADV2B":QSCV_ADV2B,
        "Over50/QSCV_ADV2D":QSCV_ADV2D,
        "Over50/QSCV_ADV5":QSCV_ADV5,
        "Over50/QSCV_ADV3":QSCV_ADV3,
        "Over50/QSCV_ADV4":QSCV_ADV4,
        "over50lifeinsurance/QSCV_ADV6":FB_ADV1,
        "Over50/QSCV_ADV2C":QSCV_ADV2C,
        "OV50_CHBQS_V2_7_3":OV50_CHBQS_V2_7_3,
        "OV50_CHBQS_V2_7_2D":OV50_CHBQS_V2_7_2D
    }
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;