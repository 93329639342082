import React from 'react'
import { Helmet } from 'react-helmet'

const Header = ({formattedPhoneNumber,phoneNumber}) => {
  return (
    <>
      {/* {(EnvConstant == "prod") ?
        <Helmet>
          <script src="//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487" async></script>
        </Helmet> : */}
        <Helmet>
          <script src="//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487" async></script>
        </Helmet>
      {/* } */}
      {/* <section>
        <div className="container text-center">
          <h5 className="mt-lg-4 mt-md-4 mt-sm-2 mt-2   text1">
            <div className="row mt-3">
              <div className="col-lg-10 offset-lg-1 ">
                <h1 className="fw-bold text2">
                  Protect your loved ones with a Guaranteed acceptance of over 50
                  life insurance
                </h1>
                <h5 className="text3">
                  Putting an affordable policy in place for your loved ones to
                  help when you are no longer around has never been so simple and
                  affordable
                </h5>
              </div>
            </div>
          </h5>
        </div>
      </section> */}
    </>
  )
}

export default Header