import React, { useState } from 'react'
import logo from '../../../../assets/img/QSCV_ADV1/logo.png';
import CookieModal from '../../../Pages/Advertorial/CookieModal';
import AnchorAdvCall from '../../../UI/AnchorAdvCall';
import { Helmet } from 'react-helmet';

const AdvHeader = ({phoneNumber,handleButtonClick}) => {
  console.log("sddfsdf");
  const [cookieModal, setCookieModal] = useState(false);
  const [sectionHide, setSectionHide] = useState(false);

  return (
    <div>
       <Helmet>
          <script src="//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487" async></script>
        </Helmet>
      <div id="cookieBar" className={`cooki text-center ${sectionHide ? "d-none" : ""}`}>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <span>
            Cookies help us deliver our services. By using this website you agree to
            use of our cookies.{" "}
            <a
              href="#"
              className="rp_clk"
              onClick={() => setCookieModal(true)}
            >
              <u>Learn More.</u>
            </a>{" "}
          </span>
          <a
            className="rp_clk closecook text-danger"
            onClick={() => setSectionHide(true)}
            style={{ color: "red!important", marginLeft: 5, fontSize: 12 }}
          >
            Close <strong>X</strong>
          </a>
        </div>
        <div className="clearfix" />
      </div>
      {/* ========= Header Area Start ========= */}
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center advpara">
              <p>Advertorial</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 logo">
              <AnchorAdvCall handleButtonClick={handleButtonClick} phoneNumber={phoneNumber}  dataId="SITE_lOGO" className="">
                {" "}
                <img src={logo} alt="" />{" "}
              </AnchorAdvCall>

            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
              <AnchorAdvCall handleButtonClick={handleButtonClick}  phoneNumber={phoneNumber}  dataId="TOP_CTA_BTN" className="get_btn btn">
                Call Now
              </AnchorAdvCall>
            </div>
          </div>
        </div>
      </header>
      {/* ========= Header Area End ========= */}
      <CookieModal
        cookieModal={cookieModal}
        setCookieModal={setCookieModal}
      />
    </div>
  )
}

export default AdvHeader
