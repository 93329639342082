import React, { useState } from 'react';
import loader_gif from '../../../../assets/img/loader-gif.gif';
const Modalloader = ({ modalBody1, setModalBody1,ModalViewers,ModalButton}) => {
  return (
    <>
      <div
        className={`modal fade show ${modalBody1 === '' ? 'd-none' : 'd-block'}`}
        tabIndex={-1}
        role='dialog'
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
        <div
          className='modal-dialog modal-dialog-centered modal-md'
          role='document'>
          <div className='modal-content bor-b5'>
            {/* <div className='modal-header noborder' /> */}
            <div className='modal-body text-center'>
              {/* <p className='unqualify-modal-p text-center fw-bold mt-4'>{modalBody}</p> */}
              <img  src={loader_gif} alt="Loader..." style={{ width: '200px'}} />

            </div>
            <div className='modal-footer justify-content-center'>
              {/* <button
                type='button'
                className='btn btn-secondary btn-anime btn_reClaim'
                data-dismiss='modal'
                onClick={(e) => { ModalViewers(1) }}>
                {ModalButton == "" || ModalButton == undefined ? ("Reclamar Beneficios") : (ModalButton)}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modalloader;
