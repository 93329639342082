import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import '../../assets/css/AccordionFaq.scss';

const AccordionFaq = ({ accordionTitle, accordionContent, accordionExtraClasses }) => {
  const FaqString = accordionContent.map((contents, index) => {
    return (
      <div
        className='faq__accordion-item'
        key={index}>
        <Accordion.Item
          // className='accordion-item'
          eventKey={index}>
          <Accordion.Header className='faq__accordion-title'>{contents.heading}</Accordion.Header>
          <Accordion.Collapse
            eventKey={index}
            id='accor-1'
            className='accordion-collapse collapse'>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: contents.content }}></div>
            </Accordion.Body>
          </Accordion.Collapse>
        </Accordion.Item>
      </div>
    );
  });
  return (
    <div className={`splitFaq ${accordionExtraClasses}`}>
      <div className='faq__accordion mt-4  mb-lg-5 mb-md-4 mb-sm-4 mb-5'>
        <Accordion className='accordion-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-8 col-lg-8 offset-lg-2'>
                <h4 className='text-center mb-4'>
                  <b>{accordionTitle}</b>
                </h4>
                {FaqString}
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionFaq;
