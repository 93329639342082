import React, { useState } from 'react';

const TermsModal = ({ termsModal, setTermsModal }) => {
  return (
    <>
      {/* ========= Terms And Conditions Start ========= */}
      <div className={`modal fade show ${termsModal ? "d-block" : "d-none"}`}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Terms and Conditions
              </h4>
              <button type="button" className="ms-2 close" onClick={() => setTermsModal(false)}>
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="blue-grid">
                  <p>
                    yourlife-insured.co.uk reserves the right, at any time and
                    without prior notice, to remove or cease to supply any product
                    or service contained on this website. In the event that such
                    removal takes place we shall not be liable to you in any way
                    whatsoever for such removal.
                  </p>
                  <p>
                    Prices, and details, of products and services (and any offers)
                    posted online are subject to change without notice. All products
                    and services are subject to availability and we give no
                    guarantee in this regard. The provision of details of products
                    and services on this website are not, and should not be
                    construed as, an offer to sell or buy such products or services
                    by the relevant company. The company advertising the products or
                    services concerned may accept or reject your offer at its sole
                    discretion
                  </p>
                  <p>
                    The copyright in the material contained in this website belongs
                    to yourlife-insured.co.uk or its licensed source. Any person may
                    copy any part of this material, subject to the following
                    conditions:
                  </p>
                  <p>The material may not be used for commercial purposes.</p>
                  <p>
                    The copies must retain any copyrights or other intellectual
                    property notices contained in the original material.
                  </p>
                  <p>
                    The products and technology or processes described in this
                    website may be subject to other intellectual property rights
                    reserved by yourlife-insured.co.uk or by other third parties
                    (and no licence is granted in respect of those intellectual
                    property rights).
                  </p>
                  <p>
                    Images on this website are protected by copyright and may not be
                    reproduced or appropriated in any manner without the written
                    permission of their respective owner(s).
                  </p>
                  <p>
                    While yourlife-insured.co.uk has taken care in the preparation
                    of the contents of this website, this website and the
                    information, names, images, pictures, logos, icons regarding or
                    relating to yourlife-insured.co.uk or the products and services
                    of the same (or to third party products and services), are
                    provided on an 'as is' basis without any representation or
                    endorsement being made and without any warranty of any kind,
                    whether express or implied, including but not limited to, any
                    implied warranties of satisfactory quality, fitness for a
                    particular purpose, non-infringement, compatibility, security
                    and accuracy. To the extent permitted by law, all such terms and
                    warranties are hereby excluded. In no event will
                    yourlife-insured.co.uk be liable (whether in contract or tort
                    (including negligence or breach of statutory duty) or otherwise)
                    for any losses sustained and arising out of or in connection
                    with use of this website including, without limitation, loss of
                    profits, loss of data or loss of goodwill (in all these cases
                    whether direct or indirect) nor any indirect, economic,
                    consequential or special loss.
                  </p>
                  <p>
                    yourlife-insured.co.uk does not represent that the information
                    contained in this website is accurate, comprehensive, verified
                    or complete, and shall accept no liability for the accuracy or
                    completeness of the information contained in this website or for
                    any reliance placed by any person on the information.
                  </p>
                  <p>
                    yourlife-insured.co.uk does not warrant that the functions or
                    materials accessible from or contained in this website will be
                    uninterrupted or error free, that defects will be corrected or
                    that this website or the server that makes it available are
                    virus or bug free or represent the full functionality, accuracy,
                    reliability of the materials.
                  </p>
                  <p>
                    If any of these Terms and Conditions (or any terms and
                    conditions relating to a product or service referred to in this
                    website) should be determined to be illegal, invalid or
                    otherwise unenforceable by reason of the laws of any state or
                    country in which such terms and conditions are intended to be
                    effective, then to the extent of such illegality, invalidity or
                    unenforceability, and in relation to such state or country only,
                    such terms or condition shall be deleted and severed from the
                    rest of the relevant terms and conditions and the remaining
                    terms and conditions shall survive, remain in full force and
                    effect and continue to be binding and enforceable.
                  </p>
                  <p>
                    All Intellectual Property Rights (including, without limitation,
                    all database rights, rights in designs, rights in know-how,
                    patents and rights in inventions (in all cases whether
                    registered or unregistered and including all rights to apply for
                    registration) and all other intellectual or industrial property
                    rights in any jurisdiction) in any information, content,
                    materials, data or processes contained in or to this website
                    belong to yourlife-insured.co.uk or its licensed source. All
                    rights of yourlife-insured.co.uk in such Intellectual Property
                    Rights are hereby reserved.
                  </p>
                  <p>
                    Unless otherwise specified, the products and services described
                    in this website are available only to UK residents (excluding
                    the Channel Islands and Isle of Man). The information on this
                    Web Site is not directed at anyone other than UK residents and
                    applications from others will, unless otherwise stated, not be
                    accepted yourlife-insured.co.uk makes no representation that any
                    product or service referred to on the website are appropriate
                    for use, or available in other locations. The information and
                    other materials contained in this website may not satisfy the
                    laws of any other country and those who choose to access this
                    site from other locations are responsible for compliance with
                    local laws if and to the extent local laws are applicable. The
                    phone numbers provided only apply to phone calls made from
                    within the UK.
                  </p>
                  <p>
                    These Terms and Conditions and any terms and conditions relating
                    to products or services described in this website shall be
                    governed by and construed in accordance with the laws of England
                    and Wales. Disputes arising in relation to the same shall,
                    unless otherwise expressly agreed, be subject to the exclusive
                    jurisdiction of the courts of England and Wales.
                  </p>
                  <p>
                    Telephone calls using the telephone numbers provided on this
                    website and email correspondence with yourlife-insured.co.uk at
                    the email addresses accessible through, or discernible from,
                    this website may be recorded or monitored. By using such
                    communication methods, you are consenting to the recording or
                    monitoring of the same.
                  </p>
                  <p>
                    If you apply for any product or service detailed on this
                    website, these Terms and Conditions should be read in
                    conjunction with any other terms and conditions which relate to
                    any such product or service and, in the event of any
                    contradiction between these Terms and Conditions and the
                    specific terms and conditions relating to such product or
                    service, the latter shall prevail. For the purposes of these
                    Terms and Conditions, product(s) and service(s) shall include,
                    without limitation, any insurance or financial service.
                  </p>
                  <p>
                    The images, logos and names on this website which identify
                    yourlife-insured.co.uk or third parties and their products and
                    services are proprietary marks of yourlife-insured.co.uk or the
                    relevant third parties. Nothing contained in this website shall
                    be deemed to confer on any person any licence or right on the
                    part of yourlife-insured.co.uk or any third party with respect
                    to any such image, logo or name.
                  </p>
                  <p>
                    We reserve the right to change these terms and conditions at any
                    time by posting changes on the website. It is your
                    responsibility to review the website terms and conditions
                    regularly to ensure you are aware of the latest terms and
                    conditions. Your use of this website after a change has been
                    posted will be deemed to signify your acceptance of the modified
                    terms and conditions. We recommend that you print off and retain
                    for your records a copy of these terms and conditions from time
                    to time and a copy of any terms and conditions relating to any
                    product or service which you apply for online, together with any
                    related application form completed and submitted. Any amendment
                    to terms and conditions must be agreed in writing by us, or, if
                    appropriate, by the relevant company with whom you contract.
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => setTermsModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsModal;
