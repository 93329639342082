import React from 'react';
import { Helmet } from 'react-helmet';
import logo from "../../../assets/img/OV50_GPOS_V3/logo.png"

const Header = ({ uniqueContainerClass, formattedPhoneNumber, phoneNumber, splitName }) => {
    return (
        <>
            {/* {(EnvConstant == "prod") ? <Helmet><script src="//b-js.ringba.com/CAfe8e30f0d9964386bac7acabadf4a10b" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>} */}
            {/* } */}
            <Helmet>
                <script
                    src='//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487'
                    async></script>
            </Helmet>
            <header className={uniqueContainerClass}>
                <div className='container py-4'>
                    <div className='row justify-content-end'>
                        <div className='col-12 logo_div single_logo text-center'>
                            <img
                                src={logo}
                                alt=''
                                className='logo ps-lg-5 ps-md-3 ps-0'
                            />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
