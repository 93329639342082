import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import logo from "../../../../assets/img/OV50_GPOS_V3/logo.png"
import { useForm } from "react-hook-form";
import CallButton_OV50_CHBQS_V2_7_2D from './CallButton_OV50_CHBQS_V2_7_2D';
import { queryString } from "../../../../Utility/QueryString";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import * as EnvConstants from "../../../../../src/Constants/EnvConstants";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";

const Header1 = ({ uniqueContainerClass, formattedPhoneNumber, phoneNumber, splitName }) => {
    const { register, errors, clearErrors, setError, trigger, getValues } =
        useForm({
            mode: "onSubmit",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });
    const { saveDataIngestion, isLoading } = useDataIngestion();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const callClick = (e) => {
        const values = getValues();
        const formData = values;
        formData.lstSalutation = '';
        formData.txtFName = '';
        formData.txtLName = '';
        formData.txtEmail = '';
        formData.txtPhone = '';
        formData.page_name = 'OV50_CHBQS_V2_7_2D'
        formSubmit(formData);
    }
    const formSubmit = async (formData) => {
        const formSUbmitResult = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "split_form_submit",
            "OV50_CHBQS_V2_7_2D",
            queryString,
            visitorParameters.data
        );
    };
    const EnvConstant = EnvConstants.AppEnv;
    return (
        <>
            {/* {(EnvConstant == "prod") ? <Helmet><script src="//b-js.ringba.com/CAfe8e30f0d9964386bac7acabadf4a10b" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>} */}
            {/* } */}
            <Helmet>
                <script
                    src='//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487'
                    async></script>
            </Helmet>
            <header className={uniqueContainerClass}>
                <div className='container'>
                    <div className='row justify-content-end'>
                        <div className='col-xl-8 col-lg-8 col-md-8 col-12 logo_div text-center'>
                            <img
                                src={logo}
                                alt=''
                                className='logo ps-lg-5 ps-md-3 ps-0'
                            />
                            <CallButton_OV50_CHBQS_V2_7_2D callClick={callClick} text="Click to Call" btnstyle="btnstyle" formattedPhoneNumber={formattedPhoneNumber} phoneNumber={phoneNumber} splitName={splitName} />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header1;
