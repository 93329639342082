import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "../../assets/css/Thankyou.scss"
import handshake from "../../assets/img/handshake.gif"

const Thankyou = () => {
  const firstName = localStorage.getItem("firstName") || "firstName";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="thankyou">
        <section className="thanku-page">
          <div className="thanks-wrap">
            <div className="thanku">
              <h1>
                Thank You {firstName},
              </h1>
              <p>
                <img height="100px" src={handshake} alt="handshake" />
              </p>
              <h6>
                One of our trusted Life Insurance advisors needs to speak with you on
                the phone briefly to get a bit more information and help determine the
                best option for you.
              </h6>
              <h6>
                And don’t worry! This phone call does NOT commit you to buying
                anything and is simply to help you choose the best option.
              </h6>
            </div>
          </div>
          <div className="thanks-wrap-2">
            <p>Stay Covered - Financial © 2024</p>
          </div>
        </section>
        <footer className="mt-2 text-center">
          <div className="container">
            <div className="offset-lg-1 col-lg-10">
              <div className="row mb-3">
                <div className=" py-2">
                  <h6>Disclaimer</h6>
                  <p>
                    Stay Covered - Financial is a trading style of Veriform Ltd and is
                    authorised and regulated by the FCA.
                  </p>
                  <p>
                    Subject to our terms and conditions Stay Covered - Financial will
                    process the information you provide us and may pass this on to our
                    named partners for marketing purposes.
                  </p>
                  <p>
                    Veriform Ltd is a company registered in England &amp; Wales,
                    registration number 07195400 and registered at 145/147 Hatfield
                    Road, St. Albans, Hertfordshire, AL1 4JY, Authorised and regulated
                    by the Financial Conduct Authority, firm reference number 690199.
                  </p>
                  <p>
                    Stay Covered - Financial is not an insurance company nor able to
                    offer any advice into any financial services regulated products.
                    Any terms and conditions may vary for all of our partners.
                  </p>
                  <p>
                    We do not charge a fee for this service, but we do receive a
                    commission directly from our partners following successful
                    introductions from this website. Any information we provide is for
                    the purposes of illustrating the products promoted on this site
                    only and should not be read as financial advice.
                  </p>
                </div>
                <div></div>
                <div>
                  <p>
                    <Link target="_blank" to="privacy">Privacy Policy</Link>
                    {" "}|{" "}
                    <Link target="_blank" to="terms">Terms and Conditions</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>

  )
}

export default Thankyou