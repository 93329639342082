import React, { useState } from 'react';
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../Includes/Layouts/Split_1/SelectBox";

const DOBSlide = ({ textDob, getValues, clearErrors, validation, setError, register, errors, className, handleDateOfBirthSubmit, handleOptionClick, setDateOfBirth, dateOfBirth }) => {

    const [dobMonth, setDobMonth] = useState('');
    const handleDateOfBirthChange = (event) => {
        const { name, value } = event.target;

        const dobMonthValue = event.target.value;
        const dobDay = document.getElementById('DobDay').value;
        const dobMonth = document.getElementById('DobMonth').value;
        const dobYear = document.getElementById('DobYear').value;
        const isLeapYear = (year) => {
            return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        };
    
        if (dobMonth === 'February' && (dobDay === '30' || dobDay === '31')) {
            setError('DobDay', {
                type: 'manual',
                message: 'Please enter valid date.',
            });
            return;
        }
    
        if (dobMonth === 'February' && dobDay === '29' && !isLeapYear(dobYear)) {
            setError('DobDay', {
                type: 'manual',
                message: 'Please enter your valid date of birth.',
            });
            return;
        }
        const daysInMonth = {
            January: 31,
            February: isLeapYear(dobYear) ? 29 : 28,
            March: 31,
            April: 30,
            May: 31,
            June: 30,
            July: 31,
            August: 31,
            September: 30,
            October: 31,
            November: 30,
            December: 31,
        };

        if (parseInt(dobDay) > daysInMonth[dobMonth]) {
            setError('DobDay', {
                type: 'manual',
                message: `Please enter valid date.`,
            });
            return;
        }

        clearErrors('DobDay');
        

        localStorage.setItem('dobDay', dobDay);
        localStorage.setItem('dobMonth', dobMonth);
        localStorage.setItem('dobYear', dobYear);
        // handleDateOfBirthSubmit({ dobDay, dobMonth, dobYear });
        setDateOfBirth(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleNextButtonClick = (e) => {
        e.preventDefault();
        let year = localStorage.getItem('dobYear');
        let month = localStorage.getItem('dobMonth');
        let day = localStorage.getItem('dobDay');
        // console.log(year , month , day);
        if (year && month && day) {
            handleDateOfBirthSubmit();
            handleOptionClick();
            clearErrors()
        }
        else {
            setError('DobDay', {
                type: 'manual',
                message: 'Please enter your date of birth',
            });
        }
        console.log(errors, 15);

    };

    return (
        <div className='col-12'>
            <div className="form-group col-12">
            <label className='slide-heading'>Enter Your Date Of Birth</label>
                <fieldset className="scheduler-border mx-4">
                    <legend className="scheduler-border">{textDob}</legend>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                                <SelectBox
                                    value={dateOfBirth.DobDay}
                                    className="form-control"
                                    OptionValue={DobDay}
                                    name="DobDay"
                                    id="DobDay"
                                    onChange={(event) => handleDateOfBirthChange(event)}
                                //   myRef={validation({
                                //       required: "Please Select Date"
                                //   })}
                                //validationMsg={errors.DobDay && errors.DobDay.message}
                                />
                                <i className="tick fa" style={{ display: 'none' }}></i>
                                {/* <span id="dobDay_err" className="error_msg"></span> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                                <SelectBox
                                    value={dateOfBirth.DobMonth}
                                    className="form-control"
                                    OptionValue={DobMonth}
                                    name="DobMonth"
                                    id="DobMonth"
                                    onChange={(event) => handleDateOfBirthChange(event)}
                                //   myRef={validation({
                                //       required: "Please Select Month"
                                //   })}
                                //validationMsg={errors.DobMonth && errors.DobMonth.message}
                                />
                                <i className="tick fa" style={{ display: 'none' }}></i>
                                {/* <span id="dobMonth_err" className="error_msg"></span> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                                <SelectBox
                                    value={dateOfBirth.DobYear}
                                    className="form-control"
                                    OptionValue={DobYear}
                                    name="DobYear"
                                    id="DobYear"
                                    onChange={(event) => handleDateOfBirthChange(event)}
                                //   myRef={validation({
                                //       required: "Please Select Year"
                                //   })}
                                // validationMsg={errors.DobYear && errors.DobYear.message}
                                />
                                <i className="tick fa" style={{ display: 'none' }}></i>
                                <span id="dobYear_err" className="error_msg"> </span>
                            </div>
                        </div>
                        {errors.DobDay && <span id="dob_final_err" className="error_msg">{errors.DobDay.message}</span>}
                    </div>

                </fieldset>
                <div className='row'>
                    <div className='pt-3 text-center'>
                        <button
                            className='next-but btn flasher my-bts sctop1 next03'
                            onClick={(e) => handleNextButtonClick(e)}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DOBSlide;