import { useState, useContext, useEffect } from "react";
import { Helmet } from 'react-helmet'
import Footer from "../../Includes/Layouts/OV50_VSLQS_V1_1/Footer_1"
import WhyStayCovered from "../../Includes/Layouts/OV50_VSLQS_V1/WhyStayCovered";
import ChatBotBubble_OV50_VSLQS_V1_2 from "./ChatBotBubble_OV50_VSLQS_V1_2";

import logos from "../../../../src/assets/img/OV50_VSLQS_V1/logos.png"
// import eleven from "../../../../src/assets/img/OV50_VSLQS_V1/11.png"
import eleven from "../../../../src/assets/img/OV50_VSLQS_V1/dummy_img.png"
// import vdo_2 from "../../../../src/assets/img/OV50_VSLQS_V1/vdo_2.mp4"
import vdo_3 from "../../../../src/assets/img/OV50_VSLQS_V1/vdo_3_Updated.mp4"
import "../../../../src/assets/css/OV50_VSLQS_V1/circlesinitiative.scss";

import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { UUID } from "../../../Utility/UUID";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import { useUserClicks } from "../../../Hooks/useUserClicks";
import RingbaApi from "../../../Utility/RingbaApi";
import OfflineSpanishButtonLink from "../../../Utility/OfflineSpanishButtonLink";
import RingbaPhoneNumber from "../../../Utility/RingbaPhoneNumber";

const OV50_VSLQS_V1_2 = () => {
  const campaign_name = "english";
  const splitName = 'OV50_VSLQS_V1_2';

  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const [buttonRedirect, setButtonRedirect] = useState();
  // let is_online = response.is_online;
  let is_online = "online";
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumber(utmName);

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  const [videoControl, setVideoControl] = useState({
    muted: true,
    play: true
  });
  const handleVideo = (control) => {
    switch (control) {
      case 'muted':
        setVideoControl(prevState => ({
          ...prevState,
          muted: !prevState.muted
        }));
        break;
      case 'play':
        const video = document.getElementById('mVideo');
        if (video.paused) {
          video.play();
          setVideoControl(prevState => ({
            ...prevState,
            play: true
          }));
        } else {
          video.pause();
          setVideoControl(prevState => ({
            ...prevState,
            play: false
          }));
        }
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="OV50_VSLQS_V1">
        <AdtopiaLoadLP PageType="LP" splitName={splitName} />
        <FbViewPixel />
        {/* {(EnvConstant == "prod") ?
        <Helmet>
          <script src="//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487" async></script>
        </Helmet> : */}
        <Helmet>
          <script src="//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487" async></script>
        </Helmet>
        {/* } */}
        <div className="wrap-content">
          <header>
            <h1>Call the Over 50’s Insurance Helpline</h1>
            <p>(Guaranteed Acceptance For UK Residents Aged 50-80)</p>
          </header>
          <div id="videoDiv" className="video-post">
            <div
              className="volume"
              id="muteBtn"
              onClick={() => handleVideo('muted')}
            >
              <i className={`bi bi-volume-${videoControl.muted ? "down" : "mute"}-fill vlicon`} />
            </div>
            <video
              poster={eleven}
              id="mVideo"
              autoPlay
              muted={videoControl.muted}
              loop
              onClick={() => handleVideo('muted')}
            >
              <source src={vdo_3} type="video/mp4" />
            </video>
          </div>
          <div className="logodiv px-3">
            <img width="100%" src={logos} alt="logos" />
          </div>
          <div className="bottm-video-content text-center">
            <ChatBotBubble_OV50_VSLQS_V1_2
              splitName={splitName}
              formSubmit={formSubmit}
              journeyStatus={journeyStatus}
              saveUser={saveUser}
              uuid={uuid}

              is_online={is_online}
              buttonRedirect={buttonRedirect}
              phoneNumber={phoneNumber}
              formattedPhoneNumber={formattedPhoneNumber}
            />
          </div>
          <WhyStayCovered />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default OV50_VSLQS_V1_2