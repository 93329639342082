import React from 'react'
import { Link } from "react-router-dom"
const Footer = () => {
  return (
    <>
     <footer className="footer_text">
        <div className="container footer-bottom">
          <div className="row text-center">
            <div className="faq__accordions col-md-12">
              <h6>Disclaimer</h6>
              <p>
                Stay Covered - Financial Services is a trading style of Veriform Ltd
                and is authorised and regulated by the FCA.
              </p>
              <p>
                Subject to our terms and conditions Stay Covered - Financial
                Services will process the information you provide us and may pass
                this on to our named partners for marketing purposes.
              </p>
              <p>
                Veriform Ltd is a company registered in England &amp; Wales,
                registration number 07195400 and registered at 145/147 Hatfield
                Road, St. Albans, Hertfordshire, AL1 4JY, Authorised and regulated
                by the Financial Conduct Authority, firm reference number 690199.
              </p>
              <p>
                Stay Covered - Financial Services is not an insurance company nor
                able to offer any advice into any financial services regulated
                products. Any terms and conditions may vary for all of our partners.
              </p>
              <p>
                We do not charge a fee for this service, but we do receive a
                commission directly from our partners following successful
                introductions from this website. Any information we provide is for
                the purposes of illustrating the products promoted on this site only
                and should not be read as financial advice.
              </p>
              <p>Stay Covered - Financial Services © 2024</p>
              <div>
              <p>
                <Link target="_blank" to="privacy">Privacy Policy</Link>
                {" "}|{" "}
                <Link target="_blank" to="terms">Terms and Conditions</Link>
              </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer