import React from 'react'
import "../../../../src/assets/css/QSCV_ADV1/custom.scss";
import logo from '../../../assets/img/QSCV_ADV1/logo.png';
import banner from '../../../assets/img/QSCV_ADV1/banner2.png';
import side_bnr from '../../../assets/img/QSCV_ADV1/side_bnr.png';
import log_wht from '../../../assets/img/QSCV_ADV1/log_wht.png';
import AdvHeader from '../../Includes/Layouts/QSCV_ADV1/AdvHeader';
import AdvFooter from '../../Includes/Layouts/QSCV_ADV1/AdvFooter';
import AdtopiaLoadAP from '../../../Utility/AdtopiaLoadAP';
import FbViewPixel from '../../../Utility/FbViewPixel';
import AnchorAdv from '../../UI/AnchorAdv';
const QSCV_ADV1 = () => {
  let yearOfBirth1 = [];
  for (var i = 1933; i <= 1972; i++) {
    yearOfBirth1.push(<AnchorAdv key={i} dataId="MID_PARA_NUM" className="banner-btn-color1">{i}</AnchorAdv>);
  }
  // let yearOfBirth2 = [];
  // for (var i = 1933; i <= 1972; i++) {
  //   yearOfBirth2.push(<AnchorAdv key={i} dataId="MID_PARA_NUM" className="banner-btn-color1">{i}</AnchorAdv>);
  // }
  return (
    <>
      <div className='yourifeinsu'>
        <AdtopiaLoadAP pageType="AP" splitName="QSCV_ADV1" />
        <FbViewPixel />
        <AdvHeader />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>
                  Born Before 1972? A Single Call Could Slash Your Life Insurance Costs!
                </h1>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                <AnchorAdv dataId="PARA_IMAGE_1">
                  <img src={banner} alt="" className="banner" />
                </AnchorAdv>
                <p className="mt-3">
                  If you have a partner and/or children, life insurance is a must. If
                  you've put off getting life cover and are now age 50 or older,
                  you've got a great chance to do the right thing for your family as
                  you're now able to get highest payout in life cover in UK for just £5 per month.*
                </p>
                <p>
                  <strong>
                    There is a big misconception in the UK with respect to life
                    insurance.
                  </strong>{" "}
                  Many people think if they're not in their 20s, 30s or 40s, they're
                  too old to be accepted for life insurance. This is a source of
                  stress in countless families because dying isn't cheap! In fact,
                  many families go into debt just to cover the cost of a loved one
                  dying.
                </p>
                <div className="greensec p-3 mb-2">
                  <p className="fw-bold">
                    See How Brits Over 50 Reduce Life Insurance Costs with Just One Call!
                  </p>
                  <ul>
                    <li>
                      <AnchorAdv dataId="PARA_DESC_1">Premiums are as low as a few quid a week</AnchorAdv>
                    </li>
                    <li>
                      <AnchorAdv dataId="PARA_DESC_1">Guaranteed acceptance with no medical exam for those aged 50 - 79</AnchorAdv>
                    </li>
                    <li>
                      <AnchorAdv dataId="PARA_DESC_1">Free Private 24/7 Doctors Hotline</AnchorAdv>
                    </li>
                    <li>
                      <AnchorAdv dataId="PARA_DESC_1">Mental Health Cover</AnchorAdv>
                    </li>
                    <li>
                      <AnchorAdv dataId="PARA_DESC_1">Quick cash release within 3 days for when your family need it the most.</AnchorAdv>
                    </li>
                  </ul>
                </div>
                <div className="cta_sec_dsk">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12 banner text-center">
                      <h2>HOW TO GET STARTED:</h2>
                      <h3>
                        <b className="bg-hglt"><span class="live_animated"></span> Live Agents Available Now</b>
                      </h3>
                      <p>
                        Click your year of birth and connect with a live specialists
                        to get your free life insurance quote
                      </p>
                      {yearOfBirth1}
                    </div>
                  </div>
                </div>
                <h2>What Do You Mean - No Medical?</h2>
                <p>
                  You read that right. If you're aged 50-80 and a permanent UK
                  resident, you are guaranteed to be accepted without having to have a
                  medical or answer any health related questions.
                </p>
                <h2>Get The Best Possible Price When Speaking To A Live Agent</h2>
                <p>
                  The internet is respsonsible for the countless improvements in our
                  lives, and one of them is the ability to shop with ease. Thanks to
                  sites like this, you can spend a minute or two with one of our live
                  specialists over the phone to ensure you get the best quote
                  available for your requirements
                </p>
                <p>
                  You've put off life cover for long enough - take a couple of minutes
                  with one of our live agents and give your family the protection they
                  deserve today.
                </p>
                <div className="cta_sec_dsk">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12 banner text-center">
                      <h2>HOW TO GET STARTED:</h2>
                      <h3>
                        <b className="bg-hglt"><span class="live_animated"></span> Live Agents Available Now</b>
                      </h3>
                      <p>
                        Click your year of birth and connect with a live specialists
                        to get your free life insurance quote
                      </p>
                      {yearOfBirth1}
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <AnchorAdv className="blue_btn btn" dataId="MAIN_CTA_BTN">
                    DO I QUALIFY
                  </AnchorAdv>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-center mb-4">
                <div className="sidebar fixedsticky">
                  <AnchorAdv dataId="RIGHT_SITE_BANNER_1">
                    <img src={side_bnr} alt="" className="side_ban" />
                  </AnchorAdv>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="fut">
          <div className="container">
            <div className="col-lg-12">
              <div className="center-block text-center log">
                <AnchorAdv dataId="SITE_lOGO" className="logo1" style={{ marginBottom: 10 }}>
                  <img src={log_wht} alt="logowht" />
                </AnchorAdv>
              </div>
              <p className="copyright">
                Choosing to take out a life insurance policy is a huge step and it can
                be daunting and scary if you don't know exactly what you are looking
                for. There are many types of cover available, and many different
                companies offering it. It is important that you understand the various
                options that are available to you. Life insurance is not a "one size
                fits all" situation, so why should you take the first plan that comes
                along when cheaper and/or more suitable options may be available?
              </p>
              <p className="copyright">
                Taking out a life insurance policy is a big thing to consider for
                anybody, and you should weigh up your options before deciding on the
                plan that is right for you. Here at YourLife Insured, we make this
                process easier by comparing hundreds of life insurance plans from the
                UK's top insurers to find the most suitable and beneficial plan for
                you based on your specific situation. Not only will working with us
                inevitably save you a lot of time, but we are sure to save you money
                because of the insurer relationship we have.
              </p>
            </div>
          </div>
        </section> */}
        <AdvFooter />
      </div>
    </>

  )
}

export default QSCV_ADV1
