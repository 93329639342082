import React, { useState, useContext, useEffect, useRef } from 'react';
import '../../../../src/assets/css/OV50_CHBQS_V2_7_1B/chat.scss';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import useJourneyStats from '../../../Hooks/useJourneyStats';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import VisitorParamsContext from '../../../Contexts/VisitorParams';
import { queryString } from '../../../Utility/QueryString';
import FbViewPixel from '../../../Utility/FbViewPixel';
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from '../../../Utility/UUID';
import ChatBotBubble_OV50_CHBQS_V2_7_1B from './ChatBotBubble_OV50_CHBQS_V2_7_1B';
import Header2 from '../../Includes/Layouts/OV50_CHBQS_V2_7/Header';
import Header from '../../Includes/Layouts/OV50_CHBQS_V2_7_1B/Header';
import Footer from '../../Includes/Layouts/OV50_CHBQS_V2_7/Footer';
import RingbaApi from '../../../Utility/RingbaApi';
import b_img from '../../../assets/img/b_img.png';
import up_btn from '../../../assets/img/up_btn.png';
import vitality from '../../../assets/img/vitality.png';
import national_friendly from '../../../assets/img/national_friendly.png';
import exeter from '../../../assets/img/exeter.png';
import axa from '../../../assets/img/axa.png';
import aviva from '../../../assets/img/aviva.png';

import OfflineSpanishButtonLink from '../../../Utility/OfflineSpanishButtonLink';
import RingbaPhoneNumber from '../../../Utility/RingbaPhoneNumber';
import AccordionFaq from '../../Accordion/AccordionFaq';
import { SplitFaq_OV50_CHBQS_V2_7 } from '../../../Constants/SplitFAQ';
import Modalloader from '../../Includes/Layouts/OV50_CHBQS_V2_7_1B/Modalloader';
import GTMDataLayer from "../common/GTMDataLayer";

const OV50_CHBQS_V2_7_1B = () => {
  const campaign_name = 'english';
  const splitName = 'OV50_CHBQS_V2_7_1B';

  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get('utm_campaign');
  const [buttonRedirect, setButtonRedirect] = useState();
  const [buttonClick, setButtonClick] = useState('');
  const [modalBody1, setModalBody1] = useState('');
  const [modalshow, setModalShow] = useState('');
  // let is_online = response.is_online;
  let is_online = 'online';
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumber(utmName);

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };


  const autofocus = useRef();
  const autofocus_initial = useRef();
  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -445;
      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });

    } else {
      window.scroll(0, 0);
    }
  }
  const handlebtn = () => {
    setButtonClick(true);
    GTMDataLayer({
      question: 'get quote',
      answer: '',
    });
    // toggleFocus();
    if (modalshow != 'showed') {
      setModalBody1("content");
      setModalShow("showed");
      setTimeout(() => {
        setModalBody1("");
      }, 2000);
    }

    const refElement = autofocus_initial.current;
      const offset = 0;
      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
  };
  return (
    <>
      <div className='OV50_CHBQS_V2_7_1B'>
        <AdtopiaLoadLP
          PageType='LP'
          splitName={splitName}
        />
        <FbViewPixel />
        <div className='body-inner'>
          <Header2 uniqueContainerClass='OV50_CHBQS_V2_7_1_header mt-1 pb-3' />
          <div>
            <section className="lightbg">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-md-7">
                    <h1>Over 50 Life Insurance</h1>
                    <h5>
                      Protect your loved ones with a Guaranteed acceptance over 50 life
                      insurance
                    </h5>
                    <p>
                      Putting an affordable policy in place for your loved ones to help when
                      you're no longer around has never been so simple and affordable
                    </p>
                    <ul className="nobulletstick">
                      <li>
                        Guaranteed
                        Acceptance
                      </li>
                      <li>
                        No medical if
                        you are a UK resident aged 50-80
                      </li>
                      <li>
                        Premiums from
                        just £5.00 per month
                      </li>
                    </ul>
                    <button
                      className="button btn-full btnthird button-lg"
                      onClick={handlebtn}
                    >
                      Get a Quote  <img src={up_btn} alt="" className='img-fluid' />
                    </button>
                  </div>
                  <div
                    className="col-md-5 d-none d-md-block"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    <img src={b_img} alt="" className='img-fluid' />
                  </div>
                </div>
              </div>
            </section>
            <div ref={autofocus_initial}></div>
            <section className="companies">
              <div className="container">
                <div className="row">
                  <div
                    className="col text-center"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    <img src={aviva} alt="aviva" />

                  </div>
                  <div
                    className="col text-center "
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    <img src={axa} alt="axa" />

                  </div>
                  <div
                    className="col   text-center"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    <img src={exeter} alt="exeter" />

                  </div>
                  <div
                    className="col text-center"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    <img src={vitality} alt="vitality" />

                  </div>
                  <div
                    className="col text-center"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    <img src={national_friendly} alt="national_friendly" />

                  </div>
                </div>

              </div>
            </section>
          </div>
          {buttonClick && (
            <div>
              <Header />
              <section className='chat-section' style={{ paddingBottom: '550px' }}>
                <div className='container'>
                  <div className=' mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3'>
                    <div className='chat-wrap'>
                      <div className='mb-1 support-online py-3 text-center'>
                        <span className='circle pulse green me-1' /> Sarah is available
                      </div>
                      <div className='chat-box-inner'>
                        <div className='row-chart-message mb-4 '>
                          <ChatBotBubble_OV50_CHBQS_V2_7_1B
                            splitName={splitName}
                            formSubmit={formSubmit}
                            journeyStatus={journeyStatus}
                            saveUser={saveUser}
                            uuid={uuid}
                            is_online={is_online}
                            buttonRedirect={buttonRedirect}
                            phoneNumber={phoneNumber}
                            formattedPhoneNumber={formattedPhoneNumber}
                            toggleFocus={toggleFocus}
                            autofocus={autofocus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
        <AccordionFaq
          accordionContent={SplitFaq_OV50_CHBQS_V2_7}
          accordionTitle='Your questions answered'
          accordionExtraClasses='accordion'
        />
        <Footer />
        <Modalloader
        modalBody1={modalBody1}
        setModalBody1={setModalBody1}
      />
      </div>
    </>
  );
};

export default OV50_CHBQS_V2_7_1B;
