//     extra parameters                                         //
//     ----------------                                         //
//  -> first_question  (boolean)                                //
//  -> final_question  (boolean)                                //
//  -> specialcss                                               //
//     -- Eg: verticleButton                                    //
//  -> dummy_text                                               //
//     -- text which has to be displayed within options but     //
//        doesnt have to be shown in the user selected section  //
//////////////////////////////////////////////////////////////////




export const QandA_OV50_GPOS_V3 = [
  {
    id: "1",
    question: [`Were you born before 1974?`],
    // specialcss: "verticleButton",
    options: [
      { id: "1", text: "YES" },
      { id: "2", text: "NO" },
    ],
  },
  {
    id: "2",
    question: [
      `Who are you looking to be insured?`,
    ],
    // specialcss: "verticleButton",
    options: [
      { id: "3", text: "Just Me" },
      { id: "4", text: "Me & My Partner" },
    ],
  },
  {
    id: "3",
    question: [
      `Why are you looking for over 50 life insurance`,
    ],
    dummyTest: `(Please check all answers that apply)`,
    multiple: true,
    options: [
      { id: "5", text: "Cover end of life expenses such as funeral costs" },
      { id: "6", text: "Leave financial security for my family" },
      { id: "7", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "8", text: "Other" },
    ],
  },
  {
    id: "4",
    question: [`Are you available to speak now to one of our licensed agents?`],
    final_question: true,
    has_call_button: true,
    dummyTest: `(To ensure guaranteed acceptance and avoid any medicals)`,
    options: [
      { id: "9", text: "Yes", specialcss: "CALL_CLICK" },
      { id: "10", text: "No" },
    ],
  },
  ];

export const QandA_OV50_GPOS_V3_6 = [
  {
    id: "1",
    question: [`Were you born before 1974?`],
    // specialcss: "verticleButton",
    options: [
      { id: "1", text: "YES" },
      { id: "2", text: "NO" },
    ],
  },
  {
    id: "2",
    question: [
      `Who are you looking to be insured?`,
    ],
    // specialcss: "verticleButton",
    options: [
      { id: "3", text: "Just Me" },
      { id: "4", text: "Me & My Partner" },
    ],
  },
  {
    id: "3",
    question: [
      `Why are you looking for over 50 life insurance`,
    ],
    dummyText: `(Please check all answers that apply)`,
    multiple: true,
    options: [
      { id: "5", text: "Cover end of life expenses such as funeral costs" },
      { id: "6", text: "Leave financial security for my family" },
      { id: "7", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "8", text: "Other" },
    ],
  },
  {
    id: "4",
    question: [`Congratulations!`],
    final_question: true,
    has_call_button: true,
    dummyText1: `Tap the button below to speak with one of our licensed agents`,
    dummyText2: `This will guarantee you the lowest possible price and help you qualify, it will only take you 2 to 3 minutes`,
    options: [
      { id: "9", text: "Call Now", specialcss: "CALL_CLICK" },

    ],
  },
];
////test
export const QandA_OV50_GPOS_FFV4 = [
  {
    id: "1",
    question: [`Were you born before 1974?`],
    // specialcss: "verticleButton",
    options: [
      { id: "1", text: "YES" },
      { id: "2", text: "NO" },
    ],
  },
  {
    id: "2",
    question: [
      `Who are you looking to be insured?`,
    ],
    // specialcss: "verticleButton",
    options: [
      { id: "3", text: "Just Me" },
      { id: "4", text: "Me & My Partner" },
    ],
  },
  {
    id: "3",
    question: [
      `Why are you looking for over 50 life insurance`,
    ],
    final_question: true,
    dummyTest: `(Please check all answers that apply)`,
    multiple: true,
    options: [
      { id: "5", text: "Cover end of life expenses such as funeral costs" },
      { id: "6", text: "Leave financial security for my family" },
      { id: "7", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "8", text: "Other" },
    ],
  },
  {
    id: "4",
    question: [`Date of Birth`],
    options: [
      { id: "dobDay", text: "dobDay"},
      { id: "dobMonth", text: "dobMonth" },
      { id: "dobYear", text: "dobYear" },
    ],
  },
];

export const QandA_OV50_GPOS_V4 = [
  {
    id: "8",
    question: [
    
      `Which of the following FREE BONUSES would you like to be included with your new Life Insurance policy?`],
      first_question: true,
      specialcss: "verticleButton",
      multiple: true,
      options: [
        { id: "19", text: " A Free Bespoke Will" },
        { id: "20", text: "Free Private GP Service 24/7 (avoid NHS queues)" },
        { id: "21", text: "Free Mental Health Cover" },
        { id: "22", text: <span style={{fontWeight:"bold",color:"#322e2e"}} className="pupular-label">I’D LIKE ALL 3 OF THE ABOVE <img src="/popular.png" className="img-style" /></span> },
      ],
  },
  {
    id: "9",
    question: [
      `Are you between the ages of 50 and 80?`,
    ],
    // specialcss: "verticleButton",
    options: [
      { id: "23", text: "YES" },
      { id: "24", text: "NO" },
    ],
  },
  {
    id: "10",
    question: [

      `Would you like your free quote to be on a policy that does NOT require a health check?`,
    ],
    options: [
      { id: "25", text: "YES" },
      { id: "26", text: "NO" },
    ],
  },
  {
    id: "4",
    question: [`Congratulations! 🤩🤩🥳🥳🥳`],
    final_question: true,
    has_call_button: true,
    dummyText1: `We are ready to offer you your free quote which will include the Free Bonuses you’ve selected above.`,
    dummyText2: `We will keep the line open for 2 minutes for you to call our Instant Quote Hotline.`,
    dummyText3: `Tap the number below to call now for your Free, No-Obligation Quote:`,
    options: [
      { id: "9", text: "Call Now", specialcss: "CALL_CLICK" },

    ],
  },
];