import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import RedirectContext from "../../Contexts/RedirectContext";

const AnchorAdvCall = ({ dataId, className, children,phoneNumber,handleButtonClick }) => {
  const { redirectUrl } = useContext(RedirectContext);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const screenSize = window.innerWidth + " x " + window.innerHeight;
  const { saveDataIngestion } = useDataIngestion();


  const clickRedirect = async (e) => {
 
    if(dataId == "TOP_CTA_BTN" ||
      dataId == "SITE_lOGO"  ||
      dataId == "MID_PARA_NUM"  ||
      dataId == "PARA_IMAGE_1" ||
      dataId == "PARA_DESC_1" ||
      dataId == "MAIN_CTA_BTN"  ||
      dataId == "RIGHT_SITE_BANNER_1"
    ){
      handleButtonClick(dataId);
    }
    if (visitorParameters.data != null || visitorParameters.data != undefined) {
      const pageOpen = visitorParameters.date;
      const pageClose = new Date();
      const hours = pageClose.getHours() - pageOpen.getHours();
      const minutes = pageClose.getMinutes() - pageOpen.getMinutes();
      const seconds = pageClose.getSeconds() - pageOpen.getSeconds();
      const time = (seconds + (minutes * 60) + (hours * 60 * 60));
      var min = "";
      var sec = "";
      var spent = "";
      if (time < 60) {
        spent = '00 : ' + time + ' sec';
      } else if (time < 3600) {
        min = (parseInt(time / 60));
        sec = (time % 60);
        spent = min + ' mts : ' + sec + ' sec';
      } else {
        var hur = (parseInt(time / 3600));
        var mnts = (time % 3600);
        min = (parseInt(mnts / 60));
        sec = (mnts % 60);
        spent = hur + ' hrs : ' + min + ' mts : ' + sec + ' sec';
      }
      visitorParameters.data.click_link = dataId;
      visitorParameters.data.redirectUrl = redirectUrl.redirectUrl;
      visitorParameters.data.timeSpent = spent;
      visitorParameters.data.screen = screenSize;
      visitorParameters.data.page = "Over50/QSCV_ADV2B";
      const advPagevClick = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        "adv_click",
        visitorParameters.pageName
      );
      // if (advPagevClick.data.status === 'Success') {
      //   const full_url = window.location.href;
      //   const query_string_arr = full_url.split("?");
      //   var queryString = "";
      //   if (query_string_arr != null) {
      //     queryString = query_string_arr[1];
      //   }
      //   localStorage.clear();
      //   if (redirectUrl.redirectUrl.indexOf('?') != -1) {
      //     var lp_url = redirectUrl.redirectUrl + '&' + queryString;
      //   }else{
      //     var lp_url = redirectUrl.redirectUrl + '?' + queryString;
      //   }
        
      //   window.location.href =`tel: ${phoneNumber}`;
      // }
    }
  };
  return (
     <><a href={`tel: ${phoneNumber}`}  className={className} data-id={dataId} onClick={clickRedirect}>{children}</a>
        {/*   <Link to="#" className={className} data-id={dataId} onClick={clickRedirect}> {children}</Link>   */}
    </>
  )
}

export default AnchorAdvCall;
