import React from 'react'
import compare from "../../../../../src/assets/img/OV50_VSLQS_V1/compare.svg"
import support from "../../../../../src/assets/img/OV50_VSLQS_V1/support.svg"
import claim from "../../../../../src/assets/img/OV50_VSLQS_V1/claim.svg"

const WhyStayCovered = () => {
  return (
    <>
      <div className="why_stay_covered mt-4 py-3 px-3">
        <h2 className="text-center">Why Stay Covered?</h2>
        <div className="mt-4 mb-4">
          <img width="10%" src={compare} alt="compare" />
          <h5>Search, compare and buy online</h5>
          <p>
            Our self-service option is simple. Pop in your details, get quotes and
            you can buy online from the leading UK insurers. Or talk to one of our
            UK advisors for a personal experience.
          </p>
        </div>
        <div className="mb-4">
          <img width="10%" src={support} alt="support" />
          <h5>Friendly, personal service</h5>
          <p>
            Whether you’re updating your current policy or joining us for the
            first time, our friendly team of experienced staff will work with you
            personally to find the cover that’s right for you.
          </p>
        </div>
        <div className="mb-4">
          <img width="10%" src={claim} alt="claim" />
          <h5>Simple claims process</h5>
          <p>
            For our Guaranteed Life Assurance, we’ve kept things simple. To make a
            claim simply complete a claim form and once approved we will pay the
            cash lump sum without further ado.
          </p>
        </div>
      </div>
    </>
  )
}

export default WhyStayCovered