import React from 'react'
import { Link } from "react-router-dom"

const Privacy = () => {
  return (
    <>
      <section className="privacy_terms">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <h2>PRIVACY POLICY</h2>
              <p>Contents</p>
              <p className="mb-1">
                <b>
                  <a href="#background">1. Background</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#how">2. How Our Website Works</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#information">3. Information about Veriform Limited</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#notice">4. What Does This Notice Cover?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#personal">5. What Is Personal Data?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#rights">6. What Are My Rights</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#collect">7. What Personal Data Do We Collect?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#use">8. How Do You Use My Personal Data?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#keep">9. How Long Will You Keep My Personal Data?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#cookies">
                    10. What Are Cookies And How Do We Use Them?
                  </a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#transfer">
                    11. How And Where Do You Store Or Transfer My Personal Data?
                  </a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#share">12. Do You Share My Personal Data?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#acces">13. How Can I Access My Personal Data?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#contact">14. How Do I Contact You?</a>
                </b>
              </p>
              <p className="mb-1">
                <b>
                  <a href="#changes">15. Changes To This Privacy Notice.</a>
                </b>
              </p>
              <p className="mb-3">
                <b>
                  <a href="#copy">16. Copyright</a>
                </b>
              </p>
              <h3 id="background">1. Background.</h3>
              <p>
                This Privacy Policy explains how we use your personal data: how it
                is collected, how it is held, and how it is processed. It also
                explains your rights under the law relating to your personal data.
              </p>
              <p>
                Veriform Limited (“We”, “Us”, “Our”) understands that your privacy
                is important to you and that you care about how your personal data
                is used. We respect and value the privacy of all of our customers
                and will only collect and use personal data in ways that are
                described here, and in a way that is consistent with our obligations
                and your rights under the law.
              </p>
              <p>
                You have certain rights in relation to your data, including the
                right to object to processing based on legitimate interests,
                profiling, and direct marketing. You can exercise your rights at any
                time by emailing us at info@veriform.co.uk. For further details
                about our processing and your rights, please see below.
              </p>
              <h3 id="how">2. How Our Website Works</h3>
              <p>
                If you’d like to request and receive quotes or information for any
                of our products, you will need to complete and submit the form on
                any of our websites.
              </p>
              <p>
                Once we’ve received your request, we’ll connect you with one or more
                Life Insurance clients (depending on the specific permissions you
                have given us). We’ll tell the Client who you are, your product or
                service requirements, any requests for information, and they’ll then
                contact you directly to discuss your requirements and potentially
                provide you with a quote or the information you have requested.
                Alternatively, Veriform Limited may phone you to pre-qualify your
                requirements before transferring the call through to our client.
              </p>
              <p>
                You’ll see on any of our websites that we have a clear consent
                statement. This statement sets out the Life Insurance clients that
                will contact you.
              </p>
              <p>
                You can choose which Life Insurance clients to buy from but you are
                not obliged to purchase from any of them.
              </p>
              <p>
                We don’t work with any Life Insurance clients until we have a legal
                contract in place and have completed a detailed due diligence
                process. This is to ensure that they respect your data and rights by
                keeping your data secure and confidential, limiting their use of
                your data (i.e., to providing you with the requested information),
                and not sharing your data with anyone else.
              </p>
              <h3 id="information">3. Information About Veriform Limited</h3>
              <p>
                Limited Liability Company registered in England under company number
                07195400.
                <br />
                Registered address: 145/147 Hatfield Road, St. Albans,
                Hertfordshire, AL1 4JY.
                <br />
                Main trading address: Spaces, The Maylands Building, Hemel
                Hempstead, Hertfordshire, HP2 7TG
                <br />
                Email address: info@veriform.co.uk
                <br />
                Telephone number: 01442 954 740
                <br />
                ICO Registration Number: Z2350000
                <br />
                FCA Authorisation Number: 690199
              </p>
              <h3 id="notice">4. What Does This Notice Cover?</h3>
              <p>
                This Privacy Information explains how we use your personal data: how
                it is collected, how it is held, and how it is processed. It also
                explains your rights under the law relating to your personal data.
              </p>
              <h3 className="personal">5. What Is Personal Data?</h3>
              <p>
                Personal data is defined by the UK General Data Protection
                Regulation (the “UK GDPR”) and Data Protection Act 2018 as ‘any
                information relating to an identified or identifiable natural person
                (‘data subject’); an identifiable natural person is one who can be
                identified, directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, location data,
                an online identifier or to one or more factors specific to the
                physical, physiological, genetic, mental, economic, cultural or
                social identity of that natural person’.
              </p>
              <p>
                Personal data is, in simpler terms, any information about you that
                enables you to be identified. Personal data covers obvious
                information such as your name and contact details, but it also
                covers less obvious information such as identification numbers,
                electronic location data, and other online identifiers.
              </p>
              <p>The personal data that we use is set out in Part 7, below.</p>
              <h3 id="rights">6. What Are My Rights?</h3>
              <p>
                Under the UK GDPR and Data Protection Act 2018, you have the
                following rights, which we will always work to uphold:
              </p>
              <ul>
                <li>
                  The right to be informed about our collection and use of your
                  personal data. This Privacy Policy should tell you everything you
                  need to know, but you can always contact us to find out more or to
                  ask any questions using the details in Part 14.
                </li>
                <li>
                  The right to access the personal data we hold about you. Part 10
                  will tell you how to do this.
                </li>
                <li>
                  The right to have your personal data rectified if any of your
                  personal data held by us is inaccurate or incomplete. Please
                  contact us using the details in Part 14 to find out more.
                </li>
                <li>
                  The right to erasure, i.e., the right to ask us to delete or
                  otherwise dispose of any of your personal data that we have,
                  subject to terms and conditions. Please contact us using the
                  details in Part 14 to find out more.
                </li>
                <li>
                  The right to restrict (i.e., prevent) the processing of your
                  personal data.
                </li>
                <li>
                  The right to object to us using your personal data for a
                  particular purpose or purposes.
                </li>
                <li>
                  The right to data portability. This means that, if you have
                  provided personal data to us directly, we are using it with your
                  consent or for the performance of a contract, and that data is
                  processed using automated means, you can ask for a copy of that
                  personal data to re-use with another service or business in many
                  cases.
                </li>
                <li>
                  Rights relating to automated decision-making and profiling. We do
                  not use your personal data in this way.
                </li>
              </ul>
              <p>
                For more information about our use of your personal data or
                exercising your rights as outlined above, please contact us using
                the details provided in Part 14.
              </p>
              <p>
                Further information about your rights can also be obtained from the
                Information Commissioner’s Office or your local Citizens Advice
                Bureau.
              </p>
              <p>
                If you have any cause for complaint about our use of your personal
                data, you have the right to lodge a complaint with the Information
                Commissioner’s Office. You can find the relevant contact details and
                the best way to make a complaint to the ICO at www.ico.org.uk.
              </p>
              <h3 id="collect">7. What Personal Data Do You Collect?</h3>
              <p>
                In order to provide you with our service we pass your details on to
                one of our Life Insurance clients so they can contact you by phone
                and/or email provide you with more information or a quote for Life
                Insurance. Alternatively, Veriform Limited may phone you to
                pre-qualify your requirements before transferring the call and/or
                your personal details through to our client. We do not knowingly,
                deliberately or aim to, collect personal data from children under
                the age of 18, either for registration or for marketing purposes. If
                you are 18 or over, we may collect some or all of the following
                personal data (this may vary according to your relationship with
                us). From time to time, we may change our websites, and so they may
                ask you for other information. It will always be clear from the
                website what information we’re requesting, and we only request
                information that is relevant to your request, and the service that
                we’re providing
              </p>
              <ul>
                <li>Title</li>
                <li>Full name</li>
                <li>Date of birth</li>
                <li>Gender</li>
                <li>Full Postal Address</li>
                <li>Email address</li>
                <li>Telephone number (Both landline and mobile)</li>
                <li>Information about your preferences and interests</li>
                <li>Demographic information</li>
                <li>
                  our IP Address. We store IP addresses in order to stop unwanted
                  traffic (i.e., spam) to our website. For example, if we become
                  aware that spam is coming from a particular IP address (i.e.,
                  computer or location), we can block that IP address.
                </li>
                <li>Cookies</li>
              </ul>
              <p>
                We do not knowingly, deliberately or aim to, collect personal data
                from children under the age of 18, either for registration or for
                marketing purposes.
              </p>
              <p>We collect your personal data in a number of ways:</p>
              <p>(a) When you fill in a website form on any of our websites.</p>
              <p>
                (b) When you write to us to enter a competition, prize-draw, or make
                an information request;
              </p>
              <p>(c) When you provide personal data on any area of our websites;</p>
              <p>
                (d) When you get in touch with us by email, social media, telephone,
                writing, and any other means;
              </p>
              <p>
                (e) When you subscribe to our services to receive communications
                from us about products and services e.g., by a newsletter, through
                social media etc.; and
              </p>
              <p>
                (f) Participate in a survey, market research, competition and/or or
                prize-draw on any of our websites.
              </p>
              <h3 id="use">8. How Do You Use My Personal Data?</h3>
              <p>
                Under the UK GDPR and Data Protection Act 2018, we must always have
                a lawful basis for using personal data. This may be because the data
                is necessary for our performance of a contract with you, because you
                have consented to our use of your personal data, or because it is in
                our legitimate business interests to use it. We collect for personal
                information in order to provide you with our service and pass your
                details on to one of our Life Insurance clients so they can contact
                you by phone and / or email and provide you with more information or
                a quote for Life Insurance. Alternatively, Veriform Limited may
                phone you to pre-qualify your requirements before transferring the
                call through to our client. The information we hold is held securely
                within an encrypted database and we will only ever transfer your
                data in a secure way.
              </p>
              <p>
                We take the protection of this data very seriously and will only
                routinely share data via secure systems with relevant Life Insurance
                clients, to progress and conclude enquiries. We may, on occasion,
                also be legally required to share data with regulatory bodies,
                including the Ministry of Justice, the Financial Ombudsman Service,
                the Financial Conduct Authority, the Legal Ombudsman and the
                Information Commissioner’s Office.
              </p>
              <p>
                Your personal data may be used for one of the following purposes
                under a legitimate interest or consent basis. The method of
                communication will be by telephone. The method of communication will
                be determined by the consent you have provided.
              </p>
              <p>To manage your registration on our websites;</p>
              <p>
                Determine whether any of the Life Insurance clients or approved
                partners we work with may have services, products and offers that
                may be of interest to you based on the preferences and permissions
                you have supplied to us; and
              </p>
              <p>
                With consent, communicate your data to our trusted partners and Life
                Insurance clients. You may object to this processing at any time by
                contacting us on the details provided in Part 14 below.
              </p>
              <p>
                Improve and personalise our website to you, and this could be where
                we may market directly to you, about other relevant offers that may
                be of interest to you based on your preferences;
              </p>
              <p>
                Depending on the consents that you have provided and what personal
                data we hold about you, we may pass your personal data to website
                sponsors or brand partners. These are our clients who are third
                parties. They may contact you by telephone and / or email for direct
                marketing purposes. Alternatively, Veriform Limited may phone you to
                pre-qualify your requirements before transferring the call through
                to our client.
              </p>
              <p>
                For analysis purposes. Where this is done, the information is
                aggregated and wherever possible anonymised in line with the ICO’s
                code of practice. These products are of a marketing nature.
              </p>
              <p>
                We may process your personal data ourselves or through third party
                data processors that will process data on our behalf. We ensure the
                data processor complies with this policy and all current data
                protection regulations.
              </p>
              <p>
                Your information will only be released to third parties where you
                have given your explicit consent, at the time of registering your
                interest on any of our websites allowing you to fully understand
                what you are consenting to.
              </p>
              <p>
                If you consent to a call from one of our sponsors or brand partners
                this will override the TPS.
              </p>
              <p>
                The legitimate interests can be your interests or our interests.
                They can include commercial interests, individual interests or
                broader societal benefits but we need to be able to identify a
                legitimate interest; show that the processing is necessary to
                achieve it; and balance it against your interests, rights and
                freedoms.
              </p>
              <p>
                With your permission and/or where permitted by law, we may also use
                your personal data for marketing purposes, which may include
                contacting you by telephone with information, news, and offers on
                our products and services. You will not be sent any unlawful
                marketing or spam. We will always work to fully protect your rights
                and comply with our obligations under the UK GDPR and Data
                Protection Act 2018 and the Privacy and Electronic Communications
                (EC Directive) Regulations 2003, and you always have the opportunity
                to opt-out.
              </p>
              <p>
                We only ever send personal information to 3rd parties where you have
                given consent to do so. To prevent fraud and to ensure data accuracy
                it is necessary to submit your information for verification and
                filtering. This enables us to provide our services to you, and
                fulfil our legal obligations. We will take reasonable technical and
                organisational precautions to prevent the loss, misuse or alteration
                of your personal information. We will store all the personal
                information you provide on our secure servers that are subject to
                strict security requirements.
              </p>
              <p>
                From time to time, Service Providers may return data to us. They may
                do this if they have a query regarding a request for Quotes that we
                have sent to them in order to enable us to resolve the query. They
                may also return data to us, together with confirmation on whether or
                not a product has been purchased, in order to enable us to improve
                our marketing.
              </p>
              <h3 id="keep">9. How Long Will You Keep My Personal Data?</h3>
              <p>
                We have a data retention policy, which clearly sets out how long we
                keep data for, and for what reasons. We will not keep your personal
                data for any longer than is necessary in light of the reasons for
                which it was first collected. Your personal data will therefore be
                kept for the following periods:
              </p>
              <p>
                We will keep your contact details following an enquiry to any of our
                websites for a period of 6 months. After 6 months your personal
                information is placed in a suppression file for a further 18 months.
                We need to do this to ensure you do not receive any further
                communication from us or from any of our partners if your
                information has been supplied to them previously.
              </p>
              <p>
                This enables us to provide our services to you, and fulfil our legal
                obligations. We will take reasonable technical and organisational
                precautions to prevent the loss, misuse or alteration of your
                personal information. We will store all the personal information you
                provide on our secure servers that are subject to strict security
                requirements.
              </p>
              <h3 id="cookies">10. What Are Cookies And How Do We Use Them?</h3>
              <p>
                Some of our websites may use cookies to collect information. You
                will be given a Cookie notification bar when you first visit any of
                our sites where your permission will be requested if that particular
                website uses Cookies. Cookies are small data files which are placed
                on your computer or other mobile or handheld device (such as smart
                ‘phones or ‘tablets’), as you browse our websites. They are used to
                ‘remember’ when your computer or device accesses our websites. We do
                not use cookies to collect or record information on users’ name,
                address or other contact details. The cookies stored on your
                computer or other device when you access our websites are set by us
                and other third parties. Cookies may also be set by third parties
                who we use for marketing. None of these third parties collect any
                personal data from which they would be able to identify individual
                customers. The main purposes for which cookies are used are:
              </p>
              <p>
                For technical purposes essential to effective operation of websites.
              </p>
              <p>
                To drive marketing, particularly banner advertisements and targeted
                updates.
              </p>
              <p>How do I disable Cookies?</p>
              <p>
                If you want to disable cookies you need to change your website
                browser settings to reject cookies. How to do this will depend on
                the browser you use, and we provide further detail below on how to
                disable cookies for the most popular browsers:
              </p>
              <p>For Microsoft Internet Explorer:</p>
              <p>Choose the menu “tools” then “Internet Options”</p>
              <p>Click on the “privacy” tab</p>
              <p>Select the setting the appropriate setting</p>
              <p>For Mozilla Firefox:</p>
              <p>Choose the menu “Tools” then “Options”</p>
              <p>Click on the icon “Privacy”</p>
              <p>Find the menu “Cookie” and select the relevant options</p>
              <p>For Opera 6.0 and further:</p>
              <p>Choose the menu “Files” &gt; “Preferences”</p>
              <p>Privacy</p>
              <p>What will happen if I disable Cookies?</p>
              <p>
                This depends on which cookies you disable. However, in general the
                website may not operate effectively if cookies are deactivated.
              </p>
              <h3 id="transfer">
                11. How And Where Do You Store Or Transfer My Personal Data?
              </h3>
              <p>
                We will only store or transfer your personal data within the
                European Economic Area (the “EEA”). The EEA consists of all EU
                member states, plus Norway, Iceland, and Liechtenstein. This means
                that your personal data will be fully protected under the UK GDPR
                and Data Protection Act 2018 or to equivalent standards by law.
              </p>
              <h3 id="share">12. Do You Share My Personal Data?</h3>
              <p>
                We will only share some or all of your personal data with select
                third parties. We will always ask for your consent before doing so
                and this is entirely your choice.
              </p>
              <p>
                In some limited circumstances, we may be legally required to share
                certain personal data, which might include yours, if we are involved
                in legal proceedings or complying with legal obligations, a court
                order, or the instructions of a government authority.
              </p>
              <p>
                If any of your personal data is required by a third party, as
                described above, we will take steps to ensure that your personal
                data is handled safely, securely, and in accordance with your
                rights, our obligations, and the third party’s obligations under the
                law, as described above in Part 8.
              </p>
              <p>
                <b>Our Life Insurance Clients;</b>
                <br />
                National Deposit Friendly Society Limited.
                <br />
                Registered office: 11-12 Queen Square, Bristol BS1 4NT. Registered
                in England and Wales no. 369F.
              </p>
              <p>
                <b>Our Data Processing Partners;</b>
                <br />
                The Read Group
                <br />
                Liquid 11 Limited
                <br />
                Brite Verify
                <br />
                Data8 Limited
                <br />
              </p>
              <h3 id="access">13. How Can I Access My Personal Data?</h3>
              <p>
                If you want to know what personal data that we have about you, you
                can ask us for details of that personal data and for a copy of it
                (where any such personal data is held). This is known as a “subject
                access request”.
              </p>
              <p>
                All subject access requests should be made in writing and sent to
                the email or postal addresses shown in Part 14. To make this as easy
                as possible for you, a Subject Access Request Form is available for
                you to use. You do not have to use this form, but it is the easiest
                way to tell us everything we need to know to respond to your request
                as quickly as possible.
              </p>
              <p>
                There is not normally any charge for a subject access request. If
                your request is ‘manifestly unfounded or excessive’ (for example, if
                you make repetitive requests) a fee may be charged to cover our
                administrative costs in responding.
              </p>
              <p>
                We will respond to your subject access request within 28 days and,
                in any case, not more than one month of receiving it. Normally, we
                aim to provide a complete response, including a copy of your
                personal data within that time. In some cases, however, particularly
                if your request is more complex, more time may be required up to a
                maximum of three months from the date we receive your request.
              </p>
              <p>You will be kept fully informed of our progress.</p>
              <h3 id="contact">14. How Do I Contact You?</h3>
              <p>
                To contact us about anything to do with your personal data and data
                protection, including to make a subject access request, please use
                the following details (for the attention of): Data Protection
                Officer
              </p>
              <p>
                Main trading address: Spaces, The Maylands Building, Hemel
                Hempstead, Hertfordshire, HP2 7TG
              </p>
              <p>Email address: info@veriform.co.uk</p>
              <p>Telephone number: 01442 954 740</p>
              <h3 id="changes">15. Changes To This Privacy Notice.</h3>
              <p>
                We may change this Privacy Policy from time to time. This may be
                necessary, for example, if the law changes, or if we change our
                business in a way that affects personal data protection.
              </p>
              <p>
                Any changes will be made available by posting a new version on our
                website. You should check this page occasionally to ensure you are
                happy with any changes, although anything that will affect you will
                be communicated directly to you.
              </p>
              <h3 id="copy">16. Copyright</h3>
              <p>
                You may download, print extracts and/or make copies of works on any
                of our websites for your own personal and non-commercial use,
                providing you acknowledge Veriform Limited as the source of the
                information and include a link to our website.
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer className="mt-5 text-center">
        <div className="container">
          <div className="offset-lg-1 col-lg-10">
            <div className="row mb-3">
              <div className=" py-2">
                <h6>Disclaimer</h6>
                <p>
                  Stay Covered - Financial is a trading style of Veriform Ltd and is
                  authorised and regulated by the FCA.
                </p>
                <p>
                  Subject to our terms and conditions Stay Covered - Financial will
                  process the information you provide us and may pass this on to our
                  named partners for marketing purposes.
                </p>
                <p>
                  Veriform Ltd is a company registered in England &amp; Wales,
                  registration number 07195400 and registered at 145/147 Hatfield
                  Road, St. Albans, Hertfordshire, AL1 4JY, Authorised and regulated
                  by the Financial Conduct Authority, firm reference number 690199.
                </p>
                <p>
                  Stay Covered - Financial is not an insurance company nor able to
                  offer any advice into any financial services regulated products.
                  Any terms and conditions may vary for all of our partners.
                </p>
                <p>
                  We do not charge a fee for this service, but we do receive a
                  commission directly from our partners following successful
                  introductions from this website. Any information we provide is for
                  the purposes of illustrating the products promoted on this site
                  only and should not be read as financial advice.
                </p>
                <p>Stay Covered - Financial © 2024</p>
              </div>
              {/* <div></div>
              <div>
                <p>
                  <Link target="_blank" to="terms">Terms and Conditions</Link>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Privacy