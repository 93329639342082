import React from 'react';
import { Helmet } from 'react-helmet';
import logo from "../../../../assets/img/OV50_GPOS_V3/logo.png"

const Header2 = ({uniqueContainerClass}) => {
  return (
    <>
      {/* {(EnvConstant == "prod") ?
    <Helmet>
      <script src="//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487" async></script>
    </Helmet> : */}
      <Helmet>
        <script
          src='//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487'
          async></script>
      </Helmet>
      {/* } */}
      <header className={uniqueContainerClass}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <img
                src={logo}
                alt=''
                className='logo'
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header2;
