import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import NotFound from "./Components/Pages/NotFound";
import PdfView from"./Components/Pages/PdfView";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import OV50_VSLQS_V1 from "./Components/Pages/OV50_VSLQS_V1/OV50_VSLQS_V1";
import Privacy from "./Components/Pages/common/Privacy";
import Terms from "./Components/Pages/common/Terms";
import OV50_CHBQS_V2 from "./Components/Pages/OV50_CHBQS_V2/OV50_CHBQS_V2";
import Thankyou from "./Components/Pages/Thankyou";
import OV50_CHBQS_V2_1 from "./Components/Pages/OV50_CHBQS_V2_1/OV50_CHBQS_V2_1";
import OV50_VSLQS_V1_1 from "./Components/Pages/OV50_VSLQS_V1_1/OV50_VSLQS_V1_1";
import OV50_GPOS_V3 from "./Components/Pages/OV50_GPOS_V3/OV50_GPOS_V3";
import OV50_GPOS_V3_5 from "./Components/Pages/OV50_GPOS_V3_5/OV50_GPOS_V3_5";
import OV50_CHBQS_V2_5 from "./Components/Pages/OV50_CHBQS_V2_5/OV50_CHBQS_V2_5";
import OV50_CHBQS_V2_6 from "./Components/Pages/OV50_CHBQS_V2_6/OV50_CHBQS_V2_6";
import OV50_VSLQS_V1_2 from "./Components/Pages/OV50_VSLQS_V1_2/OV50_VSLQS_V1_2";
import OV50_VSLQS_V1_3 from "./Components/Pages/OV50_VSLQS_V1_3/OV50_VSLQS_V1_3";
import OV50_GPOS_V3_6 from "./Components/Pages/OV50_GPOS_V3_6/OV50_GPOS_V3_6";
import OV50_GPOS_V3_7 from "./Components/Pages/OV50_GPOS_V3_7/OV50_GPOS_V3_7";
import OV50_CHBQS_V2_7 from "./Components/Pages/OV50_CHBQS_V2_7/OV50_CHBQS_V2_7";
import OV50_CHBQS_V2_7_1 from "./Components/Pages/OV50_CHBQS_V2_7_1/OV50_CHBQS_V2_7_1";
import OV50_CHBQS_V2_5B from "./Components/Pages/OV50_CHBQS_V2_5B/OV50_CHBQS_V2_5B";
import OV50_CHBQS_V2_7_1B from "./Components/Pages/OV50_CHBQS_V2_7_1B/OV50_CHBQS_V2_7_1B";
import OV50_CHBQS_V2_7_2 from "./Components/Pages/OV50_CHBQS_V2_7_2/OV50_CHBQS_V2_7_2";
import OV50_CHBQS_V2_7_2VA from "./Components/Pages/OV50_CHBQS_V2_7_2VA/OV50_CHBQS_V2_7_2VA";
import OV50_GPOS_FFV4 from "./Components/Pages/OV50_GPOS_FFV4/OV50_GPOS_FFV4";
import OV50_GPOS_FFV5 from "./Components/Pages/OV50_GPOS_FFV5/OV50_GPOS_FFV5";
import QSCV_ADV1 from "./Components/Pages/Advertorial/QSCV_ADV1";
import DateOfBirthNew from "./Utility/DateOfBirthNew";
import QSCV_ADV2 from "./Components/Pages/Advertorial/QSCV_ADV2";
import QSCV_ADV3 from "./Components/Pages/Advertorial/QSCV_ADV3";
import QSCV_ADV5 from "./Components/Pages/Advertorial/QSCV_ADV5";
import Optimize2convert from "./Components/Pages/Optimize2convert/Optimize2convert";
import QSCV_ADV4 from "./Components/Pages/Advertorial/QSCV_ADV4";
import QSCV_ADV2B from "./Components/Pages/Advertorial/QSCV_ADV2B";
import OV50_CHBQS_V2_7_2B from "./Components/Pages/OV50_CHBQS_V2_7_2B/OV50_CHBQS_V2_7_2B";
import OV50_CHBQS_V2_7_2B_1 from "./Components/Pages/OV50_CHBQS_V2_7_2B_1/OV50_CHBQS_V2_7_2B_1";
import QSCV_ADV2D from "./Components/Pages/Advertorial/QSCV_ADV2D";
import FB_ADV1 from "./Components/Pages/Advertorial/FB_ADV1";
import QSCV_ADV2C from "./Components/Pages/Advertorial/QSCV_ADV2C";
import OV50_GPOS_V4 from "./Components/Pages/OV50_GPOS_V4/OV50_GPOS_V4";
import OV50_CHBQS_V2_7_2BVA from "./Components/Pages/OV50_CHBQS_V2_7_2BVA/OV50_CHBQS_V2_7_2BVA";
import OV50_CHBQS_V2_7_2C from "./Components/Pages/OV50_CHBQS_V2_7_2C/OV50_CHBQS_V2_7_2C";
import OV50_CHBQS_V2_7_3 from "./Components/Pages/OV50_CHBQS_V2_7_3/OV50_CHBQS_V2_7_3";
import OV50_CHBQS_V2_7_2D from "./Components/Pages/OV50_CHBQS_V2_7_2D/OV50_CHBQS_V2_7_2D";
import OptimizeYT from "./Components/Pages/OptimizeYT/OptimizeYT";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={"/privacy"} component={Privacy} />
              <Route exact path={"/terms"} component={Terms} />
              <Route exact path={"/OV50_VSLQS_V1"} component={OV50_VSLQS_V1} />
              <Route exact path={"/OV50_VSLQS_V1_1"} component={OV50_VSLQS_V1_1} />
              <Route exact path={"/OV50_VSLQS_V1_2"} component={OV50_VSLQS_V1_2} />
              <Route exact path={"/OV50_VSLQS_V1_3"} component={OV50_VSLQS_V1_3} />
              <Route exact path={"/OV50_CHBQS_V2"} component={OV50_CHBQS_V2} />
              <Route exact path={"/OV50_CHBQS_V2_1"} component={OV50_CHBQS_V2_1} />
              <Route exact path={"/OV50_GPOS_V3"} component={OV50_GPOS_V3} />
              <Route exact path={"/OV50_GPOS_V3_5"} component={OV50_GPOS_V3_5} />
              <Route exact path={"/OV50_GPOS_V3_6"} component={OV50_GPOS_V3_6} />
              <Route exact path={"/OV50_GPOS_V3_7"} component={OV50_GPOS_V3_7} />
              <Route exact path={"/OV50_CHBQS_V2_5"} component={OV50_CHBQS_V2_5} />
              <Route exact path={"/OV50_CHBQS_V2_6"} component={OV50_CHBQS_V2_6} />
              <Route exact path={"/OV50_CHBQS_V2_7"} component={OV50_CHBQS_V2_7} />
              <Route exact path={"/OV50_CHBQS_V2_7_1"} component={OV50_CHBQS_V2_7_1} />
              <Route exact path={"/OV50_CHBQS_V2_5B"} component={OV50_CHBQS_V2_5B} />
              <Route exact path={"/OV50_CHBQS_V2_7_1B"} component={OV50_CHBQS_V2_7_1B} />
              <Route exact path={"/OV50_CHBQS_V2_7_2"} component={OV50_CHBQS_V2_7_2} />
              <Route exact path={"/OV50_CHBQS_V2_7_2VA"} component={OV50_CHBQS_V2_7_2VA} />
              <Route exact path={"/OV50_GPOS_FFV4"} component={OV50_GPOS_FFV4} />
              <Route exact path={"/OV50_GPOS_FFV5"} component={OV50_GPOS_FFV5} />
              <Route exact path={"/Optimize"} component={Optimize2convert} />
              <Route exact path={"/Over50/QSCV_ADV1"} component={QSCV_ADV1} />
              <Route exact path={"/Over50/QSCV_ADV2"} component={QSCV_ADV2} />
              <Route exact path={"/Over50/QSCV_ADV2B"} component={QSCV_ADV2B} />
              <Route exact path={"/Over50/QSCV_ADV2D"} component={QSCV_ADV2D} />
              <Route exact path={"/Over50/QSCV_ADV5"} component={QSCV_ADV5} />
              <Route exact path={"/Over50/QSCV_ADV3"} component={QSCV_ADV3} />
              <Route exact path={"/DateOfBirthNew"} component={DateOfBirthNew} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/Over50/QSCV_ADV4"} component={QSCV_ADV4} />
              <Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>
              <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
              <Route exact path={"/OV50_CHBQS_V2_7_2B"} component={OV50_CHBQS_V2_7_2B} />
              <Route exact path={"/OV50_CHBQS_V2_7_2B_1"} component={OV50_CHBQS_V2_7_2B_1} />
              <Route exact path={"/over50lifeinsurance/QSCV_ADV6"} component={FB_ADV1} />
              <Route exact path={"/Over50/QSCV_ADV2C"} component={QSCV_ADV2C} />
              <Route exact path={"/OV50_GPOS_V4"} component={OV50_GPOS_V4} />
              <Route exact path={"/OV50_CHBQS_V2_7_2BVA"} component={OV50_CHBQS_V2_7_2BVA} />
              <Route exact path={"/OV50_CHBQS_V2_7_2C"} component={OV50_CHBQS_V2_7_2C} />
              <Route exact path={"/OV50_CHBQS_V2_7_3"} component={OV50_CHBQS_V2_7_3} />
              <Route exact path={"/OV50_CHBQS_V2_7_2D"} component={OV50_CHBQS_V2_7_2D} />
              <Route exact path={"/OptimizeYT"} component={OptimizeYT} />


            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
