import { useState, useContext, useEffect } from "react";
import Footer from "../../Includes/Layouts/OV50_VSLQS_V1/Footer";
import Header from "../../Includes/Layouts/OV50_GPOS_V3/Header";
import ChatBotBubble_OV50_GPOS_V4 from "./ChatBotBubble_OV50_GPOS_V4";

import banner_logos from "../../../../src/assets/img/OV50_GPOS_V3/banner-logos.png";
import "../../../../src/assets/css/OV50_GPOS_V3/OV50_GPOS_V3.scss";

import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { UUID } from "../../../Utility/UUID";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import { useUserClicks } from "../../../Hooks/useUserClicks";
import RingbaApi from "../../../Utility/RingbaApi";
import OfflineSpanishButtonLink from "../../../Utility/OfflineSpanishButtonLink";
import RingbaPhoneNumber from "../../../Utility/RingbaPhoneNumber";
import WhyStayCovered from "../OV50_GPOS_V3/WhyStayCovered";


const OV50_GPOS_V4 = () => {
  const campaign_name = "english";
  const splitName = "OV50_GPOS_V4";

  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const [buttonRedirect, setButtonRedirect] = useState();
  // let is_online = response.is_online;
  let is_online = "online";
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumber(utmName);

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = "";
    values.lstSalutation = "";
    values.txtFName = "";
    values.txtLName = "";
    values.txtEmail = "";
    values.txtPhone = "";
    values.page_name = splitName;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      "split_form_submit",
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(
      uuid,
      slide_type,
      slide_question,
      slide_value,
      type,
      splitName
    );
  };

  return (
    <>
      <div className="OV50_GPOS_V3">
        <AdtopiaLoadLP PageType="LP" splitName={splitName} />
        <FbViewPixel />
        <Header />
        <div className="body-section">
          <section className="SECT">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12 pad0 z-index-1">
                  <h1 className="d-block d-sm-none d-md-none d-lg-none formpartsec">
                    {/* Call the <br />
                    <span className='headspan'>StayCovered </span>Helpline */}
                    {/* Call the Over 50’s Insurance Helpline */}
                    Get Life Insurance for less than £10 per month!
                  </h1>
                  {/* <h2 className="d-block d-sm-none d-md-none d-lg-none fw-bold mx-2">
                    Guaranteed Acceptance For UK Residents Aged 50-80
                  </h2> */}

                  <div
                    className="d-block d-sm-none d-md-none d-lg-none fw-bold mx-2 listbx">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <ul style={{ textAlign: "left", fontSize: "18px" }}>
                        <li>Free, Instant Quote</li>
                        <li>Guaranteed Coverage (No Health Checks)</li>
                        <li>Get Covered Immediately</li>
                      </ul>
                    </div>
                  </div>
                  <div className="form_part">
                    <h1 className="d-none d-sm-block d-md-block d-lg-block formpartsec">
                      {/* Call the <span className='headspan'>StayCovered </span>Helpline */}
                      {/* Call the Over 50’s Insurance Helpline */}
                      Get Life Insurance for less than £10 per month!
                    </h1>
                    {/* <h2 className="d-none d-sm-block d-md-block d-lg-block fw-bold mx-2">
                      
                     </h2> */}

                    <div
                      className="d-none d-sm-block d-md-block d-lg-block fw-bold mx-2 listbx_desk" >
                      
                        <ul>
                          <li>Free, Instant Quote</li>
                          <li>Guaranteed Coverage (No Health Checks)</li>
                          <li>Get Covered Immediately</li>
                        </ul>
                     
                    </div>

                    <div className="logosec d-none d-sm-block">
                      <div className="insurer-logos navbar-expand-lg navbar-light">
                        <img width={"100%"} src={banner_logos} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-sm-none d-md-none d-block">
                      <span className="start-but">
                        Start
                        <br />
                        {/* <img
                        src={arrow_b}
                        alt=''
                      /> */}
                      </span>
                    </div>
                    <div className="clearfix"></div>
                    <ChatBotBubble_OV50_GPOS_V4
                      splitName={splitName}
                      formSubmit={formSubmit}
                      journeyStatus={journeyStatus}
                      saveUser={saveUser}
                      uuid={uuid}
                      is_online={is_online}
                      buttonRedirect={buttonRedirect}
                      phoneNumber={phoneNumber}
                      formattedPhoneNumber={formattedPhoneNumber}
                    />
                    <div className="logosec d-block d-sm-none">
                      <div className="insurer-logos navbar-expand-lg navbar-light">
                        <img width={"100%"} src={banner_logos} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </section>
          <section className="fut">
            <div className="container px-0">
              <div className="col-lg-12">
                <WhyStayCovered />
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OV50_GPOS_V4;
