//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//  -> multiple             //
//////////////////////////////

export const QandA_OV50_CHBQS_V2 = [
  {
    id: "1",
    question: [`Hi!`,
      `Before we get started, please confirm you were born before 1974?`],
    first_question: true,
    options: [
      { id: "1", text: "Yes" },
      { id: "2", text: "No" },
    ],
  },
  {
    id: "5",
    question: [`Excellent, thank you.`,
      `Are you looking for coverage for just you, or you and your partner?`],
    specialcss: "verticleButton",
    options: [
      { id: "11", text: "Me" },
      { id: "12", text: "Me & My Partner" },
    ],
  },
  {
    id: "6",
    question: [
      `Okay, great`,
      `To help find you the best quote/policy possible, please tell us whats important to you when considering coverage options?`,
    ],
    specialcss: "verticleButton",
    multiple: true,
    options: [
      { id: "13", text: "Cover end of life expenses such as funeral costs" },
      { id: "14", text: "Leave financial security for my family" },
      { id: "15", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "16", text: "Other" },
    ],
  },
  {
    id: "7",
    question: [
      `Congratulations! 🤩🤩🥳🥳🥳`,
      `It looks like we can get you maximum coverage with guaranteed acceptance.`,
      `Are you available now to speak with one of our licensed agents to avoid any medicals and ensure the lowest possible price?`,
    ],
    options: [
      { id: "17", text: "Yes", specialcss: "CALL_CLICK", },
      { id: "18", text: "No" },
    ],
  },
  {
    id: "basic_info",
    question: [
      `Thank you for taking the time.`,
      `Please provide some of your details so our agent can assist you with this claim.`,
      `Help us with your Name`,
    ],
    specialcss: "form1",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "phone_number",
    question: [
      `Thank you for taking the time.`,
      `Kindly provide your current phone number and Email to ensure effective communication.`,
    ],
    specialcss: "form2",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "email",
    question: [
      `Thank you for that`,
      `Please provide your email address.`,
    ],
    specialcss: "form3",
    // final_question: true,
    options: [
      {},
    ],
  },
];