//     extra parameters                                         //
//     ----------------                                         //
//  -> first_question  (boolean)                                //
//  -> final_question  (boolean)                                //
//  -> specialcss                                               //
//     -- Eg: verticleButton                                    //
//  -> dummy_text                                               //
//     -- text which has to be displayed within options but     //
//        doesnt have to be shown in the user selected section  //
//////////////////////////////////////////////////////////////////

export const QandA_OV50_VSLQS_V1 = [
  {
    id: "1",
    question: [`Were you born before 1974?`],
    // specialcss: "verticleButton",
    options: [
      { id: "1", text: "YES" },
      { id: "2", text: "NO" },
    ],
  },
  {
    id: "2",
    question: [
      `Who are you looking to be insured?`,
    ],
    specialcss: "verticleButton",
    options: [
      { id: "3", text: "Just Me" },
      { id: "4", text: "Me & My Partner" },
    ],
  },
  {
    id: "3",
    question: [
      `Why are you looking for over 50 life insurance`,
    ],
    dummyTest: `Please check all answers that apply.`,
    multiple: true,
    options: [
      { id: "5", text: "Cover end of life expenses such as funeral costs" },
      { id: "6", text: "Leave financial security for my family" },
      { id: "7", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "8", text: "Other" },
    ],
  },
  {
    id: "4",
    question: [`Are you available to speak now to one of our licensed agents?`],
    final_question: true,
    has_call_button: true,
    dummyTest: `(To ensure guaranteed acceptance and avoid any medicals)`,
    options: [
      { id: "9", text: "Yes", specialcss: "CALL_CLICK" },
      { id: "10", text: "No" },
    ],
  },
];

export const QandA_OV50_VSLQS_V1_1 = [
  {
    id: "1",
    question: [`Were you born before 1974?`],
    // specialcss: "verticleButton",
    options: [
      { id: "1", text: "YES" },
      { id: "2", text: "NO" },
    ],
  },
  {
    id: "2",
    question: [
      `Who are you looking to be insured?`,
    ],
    specialcss: "verticleButton",
    options: [
      { id: "3", text: "Just Me" },
      { id: "4", text: "Me & My Partner" },
    ],
  },
  {
    id: "3",
    question: [
      `Why are you looking for over 50 life insurance`,
    ],
    dummyTest: `Please check all answers that apply.`,
    multiple: true,
    options: [
      { id: "5", text: "Cover end of life expenses such as funeral costs" },
      { id: "6", text: "Leave financial security for my family" },
      { id: "7", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "8", text: "Other" },
    ],
  },
  {
    id: "4",
    question: [`Are you available to speak now to one of our licensed agents?`],
    final_question: true,
    has_call_button: true,
    dummyTest: `(To ensure guaranteed acceptance and avoid any medicals)`,
    options: [
      { id: "9", text: "Yes", specialcss: "CALL_CLICK" },
      { id: "10", text: "No" },
    ],
  },
];

export const QandA_OV50_VSLQS_V1_2 = [
  {
    id: "1",
    question: [`Were you born before 1974?`],
    // specialcss: "verticleButton",
    options: [
      { id: "1", text: "YES" },
      { id: "2", text: "NO" },
    ],
  },
  {
    id: "2",
    question: [
      `Who are you looking to be insured?`,
    ],
    specialcss: "verticleButton",
    options: [
      { id: "3", text: "Just Me" },
      { id: "4", text: "Me & My Partner" },
    ],
  },
  {
    id: "3",
    question: [
      `Why are you looking for over 50 life insurance`,
    ],
    dummyTest: `Please check all answers that apply.`,
    multiple: true,
    options: [
      { id: "5", text: "Cover end of life expenses such as funeral costs" },
      { id: "6", text: "Leave financial security for my family" },
      { id: "7", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "8", text: "Other" },
    ],
  },
  {
    id: "4",
    question: [
      `Congratulations!`,
    ],
    // specialcss: "verticleButton",
    final_question: true,
    has_call_button: true,
    dummyTest1:' Tap the button below to speak with one of our licensed agents.',
    dummyTest2:'This will guarantee you the lowest possible price and help you qualify, it will only take you 2 to 3 minutes.',
    options: [
      { id: "9", text: "Call Now", specialcss: "CALL_CLICK" },
    ],
  },
];