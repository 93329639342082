import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useHistory, Link } from "react-router-dom";
import { QandA_OV50_GPOS_V4 as QandA } from "../../../Constants/slideConstants/OV50_GPOS_V3_constants";
import ProgressBar from "../OV50_GPOS_V3/ProgressBar";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import TextField from "../../UI/TextField";
import { useForm } from "react-hook-form";
import { Salutation } from "../../../Constants/Constants";
import SelectBox from "../../UI/SelectBox";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import UnqualifiedModal from "../../Includes/Layouts/UnqualifiedModal";
import GTMDataLayer from "../common/GTMDataLayer";
// import DateOfBirthNew from "../../../Utility/DateOfBirthNew";
import ico_safe from "../../../assets/img/OV50_GPOS_V3/ico-safe.png";
import arrow from "../../../assets/img/OV50_GPOS_V3/arrow.png";
import confetti from "canvas-confetti";
import loader_gif from "../../../assets/img/loader-gif.gif";
import Timer from "./Timer";

const ChatBotBubble_OV50_GPOS_V4 = ({
  formSubmit,
  splitName,
  uuid,
  journeyStatus,
  saveUser,

  is_online,
  buttonRedirect,
  phoneNumber,
  formattedPhoneNumber,
}) => {
  const {
    trigger,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const { visitorParameters } = useContext(VisitorParamsContext);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  // const [modalBody, setModalBody] = useState("");
  const [unqlifiedModalBody, setUnqlifiedModalBody] = useState("");
  const [optionvalue, setoptionvalue] = useState("");
  const { saveDataIngestion } = useDataIngestion();
  const [phoneValid, setPhoneValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const autofocus = useRef();
  const history = useHistory();
  const [selectedValues, setSelectedValues] = useState({});
  const [activateCallBtn, setActivateCallBtn] = useState(false);
  const questions = QandA;
  const [showTime, setShowTime] = useState(false);

  //   await new Promise((resolve) => setTimeout(resolve, 2000));

  useEffect(() => {
    localStorage.removeItem("questionData");
  }, []);

  // for journeyStatus view
  useEffect(() => {
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;

    if (questionId && uuid) {
      if (questionId != "4") {
        journeyStatus({
          slide_type: "question",
          slide_question: questionId,
          slide_value: "",
          type: "view",
        });
      }
      if (selectedQuestion.has_call_button) {
        journeyStatus({
          slide_type: "button",
          slide_question: "CALL_CLICK",
          slide_value: "",
          type: "view",
        });
      }
    }
    if (selectedQuestion.final_question) {
      setTimeout(() => {
        setActivateCallBtn(true);
        confetti({
          particleCount: 100,
          spread: 100,
          origin: { y: 0.9 },
        });
      }, 1500);
    }
  }, [currentQuestionIndex, uuid]);

  const gtmDataLayer = (current_question, element) => {
    let question = "";
    let answer = "";

    switch (current_question) {
      case "1":
        question = "born year";
        break;
      case "2":
        question = "insuring for";
        break;
      case "3":
        question = "looking for insurance";
        break;
      case "4":
        if (element == 9) {
          question = "ringba call";
        } else {
          question = "not available";
        }
        break;
    }
    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  const handleOptionClick = (option) => {
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = option;
    const storedOptions = localStorage.getItem("questionData");
    const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
    const existingOptions = parsedOptions["question_" + questionId] || [];

    // GTMDataLayer checking except question3
    if (
      (option !== "multiple_continue" && questionId == 1) ||
      questionId == 9 ||
      questionId == 4
    ) {
      gtmDataLayer(questionId, option);
    }

    if (
      questionId === "8" &&
      optionId === "19" &&
      optionId === "20" &&
      optionId === "21" &&
      optionId === "22"
    ) {
      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });
      //   setUnqlifiedModalBody('Sorry, we are not able to help if your age is below 50');
      return 0;
    }
    if (questionId === "9" && optionId === "24") {
      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });
      setUnqlifiedModalBody(
        "Sorry, we are not able to help if your age is below 50"
      );
      return 0;
    }

    if (option !== "multiple_continue") {
      if (questions[currentQuestionIndex].multiple) {
        // Check if the optionId is already present
        const optionIndex = existingOptions.indexOf(optionId);

        // console.log(parsedOptions['question_' + questionId],"FGDFGD")
        if (optionIndex === -1) {
          // Add the optionId if not present
          existingOptions.push(optionId);
        } else {
          // Remove the optionId if already present
          existingOptions.splice(optionIndex, 1);
        }
        if (questionId != "4") {
          parsedOptions["question_" + questionId] = existingOptions;
        }
      } else {
        // Replace the value with the optionId
        if (questionId != "4") {
          parsedOptions["question_" + questionId] = [optionId];
        }
      }
      console.log(questionId, "existingOptions");
      localStorage.setItem("questionData", JSON.stringify(parsedOptions));

      // set selected value in a state
      setSelectedValues((prevSelectedValues) => {
        if (questions[currentQuestionIndex].multiple) {
          // For questions allowing multiple options, update or replace the values
          return {
            ...prevSelectedValues,
            [questionId]: existingOptions,
          };
        } else {
          // For questions allowing single option, replace the value
          return {
            ...prevSelectedValues,
            [questionId]: [optionId],
          };
        }
      });
    } else {
      setError(`check${questions[currentQuestionIndex].id}`, {
        type: "manual",
        message: "Please select an option",
      });
      if (!existingOptions.length) return;
    }

    if (option === "multiple_continue" && selectedQuestion.multiple) {
      if (questionId == 10) {
        gtmDataLayer(questionId, option);
      }
      const firstOptionId = existingOptions[0]; // Get the first option ID
      journeyStatus({
        slide_type: questionId === "CALL_CLICK" ? "button" : "question",
        slide_question: questionId,
        slide_value: firstOptionId,
        type: "click",
      });
    } else if (option !== "continue" && !selectedQuestion.multiple) {
      if (questionId != "4") {
        journeyStatus({
          slide_type: questionId === "CALL_CLICK" ? "button" : "question",
          slide_question: questionId,
          slide_value: optionId,
          type: "click",
        });
      }
    }

    if (selectedQuestion.has_call_button) {
      // Find the selected option based on optionId
      const selectedOption = selectedQuestion.options.find(
        (option) => option.id === optionId
      );

      if (selectedOption.specialcss === "CALL_CLICK") {
        setShowTime(true);
        journeyStatus({
          slide_type: "button",
          slide_question: "CALL_CLICK",
          slide_value: "",
          type: "click",
        });
      }
    }

    if (questions[currentQuestionIndex].final_question === true) {
      if (optionId == "10") {
        setoptionvalue(optionId);
      } else {
        formSubmit(JSON.parse(localStorage.getItem("questionData")));
        saveUser("CALL_CLICK", 1);
      }
    }
    if (currentQuestionIndex + 1 < questions.length) {
      if (
        !questions[currentQuestionIndex].multiple ||
        option === "multiple_continue"
        // && !questions[currentQuestionIndex].final_question
      ) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setAnsweredQuestions([...answeredQuestions, questionId]);
      }
    }
  };

  useEffect(() => {
    if (questions[currentQuestionIndex].multiple) {
      const questionId = questions[currentQuestionIndex].id;

      const storedOptions = localStorage.getItem("questionData");
      const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
      const existingOptions = parsedOptions["question_" + questionId] || [];

      setSelectedValues((prevSelectedValues) => {
        return {
          ...prevSelectedValues,
          [questionId]: existingOptions,
        };
      });
    }
  }, [currentQuestionIndex]);

  const toggleFocus = (event) => {
    const scroll =
      window.screen.availWidth < 768
        ? questions[currentQuestionIndex].multiple
          ? 220
          : optionvalue
          ? 300
          : 80
        : questions[currentQuestionIndex].multiple
        ? 320
        : optionvalue
        ? 290
        : 170;
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -250;

      const scrollPosition =
        refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scroll(0, scroll);
    } else {
      window.scroll(0, scroll);
    }
  };

  const onSubmit = async (data) => {
    const lstSalutation_1 = await trigger("lstSalutation");
    const txtFName_1 = await trigger("txtFName");
    const txtLName_1 = await trigger("txtLName");
    // const DobDay_1 = await trigger("DobDay");
    // const DobMonth_1 = await trigger("DobMonth");
    // const DobYear_1 = await trigger("DobYear");

    if (
      !lstSalutation_1 ||
      !txtFName_1 ||
      !txtLName_1
      // || !DobDay_1 || !DobMonth_1 || !DobYear_1
    ) {
      if (!phoneValid && !emailValid) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        setError("txtEmail", {
          type: "manual",
          message: "Please Enter Valid Email",
        });
      }
      return;
    } else if (!phoneValid) {
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Working Phone Number",
      });
      return;
    } else if (!emailValid) {
      setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
      return;
    }
    GTMDataLayer({
      question: "personal details",
      answer: null,
    });
    // Retrieve existing form data from local storage
    const storedFormData =
      JSON.parse(localStorage.getItem("questionData")) || {};

    const { lstSalutation, txtFName, txtLName, DobDay, DobMonth, DobYear } =
      getValues();
    const txtPhone = document.getElementById("txtPhone").value;
    const txtEmail = document.getElementById("txtEmail").value;
    // Add question data to the existing form data
    const updatedFormData = {
      // ...data,
      lstSalutation: lstSalutation,
      txtFName: txtFName,
      txtLName: txtLName,
      txtPhone: txtPhone,
      txtEmail: txtEmail,
      // DobDay: DobDay,
      // DobMonth: DobMonth,
      // DobYear: DobYear,
      ...storedFormData,
      page_name: splitName,
    };

    journeyStatus({
      slide_type: "question",
      slide_question: "basic_info",
      slide_value: "",
      type: "click",
    });

    localStorage.setItem("firstName", txtFName);
    // Save updated form data back to local storage
    localStorage.setItem("questionData", JSON.stringify(updatedFormData));

    // Submit the form data
    const queryString = "";
    const formSubmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      updatedFormData,
      "split_form_submit",
      splitName,
      queryString,
      visitorParameters.data
      // Pass page_name field here
    );

    history.push("/thankyou?uuid=" + uuid);
  };

  const { phoneValidation } = usePhoneValidation();
  const { emailValidation } = useEmailValidation();
  const phoneValidCheck = useCallback(
    async (event) => {
      const values = getValues();
      const phone = event.target.value;

      if (phone !== "") {
        const getPhoneValidation = await phoneValidation(phone);
        // setPhoneCheck(getPhoneValidation.data.status_code);
        if (getPhoneValidation.data.status_code === 1) {
          setPhoneValid(true);
          clearErrors("txtPhone");
          return 1;
        } else if (getPhoneValidation.data.status_code === 0) {
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Working Phone Number",
          });
          setPhoneValid(false);
          return 0;
        } else if (getPhoneValidation.data.status_code === 2) {
          setError("txtPhone", {
            type: "manual",
            message: "Phone Number Already Exist",
          });
          setPhoneValid(false);
          return 0;
        } else {
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Phone Number",
          });
          setPhoneValid(false);
          return 0;
        }
      } else {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Phone Number",
        });
        setPhoneValid(false);
        return 0;
      }
    },
    [getValues]
  );
  const emailValidCheck = async (e) => {
    const values = getValues();
    // const email = values['txtEmail'];
    const email = e.target.value;
    const txtEmail = await trigger("txtEmail");
    if (txtEmail) {
      const getEmailValidation = await emailValidation(email, uuid);
      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
        setEmailValid(false);
        return 0;
      } else {
        clearErrors("txtEmail");
        setEmailValid(true);
        return 1;
      }
    }
  };
  return (
    <>
      <div className="col-12 top_minus_1">
        <ProgressBar
          value={optionvalue ? 90 : (currentQuestionIndex + 1) * 25}
        />
        {!optionvalue && (
          <div className="col-lg-12 col-md-12 col-sm-12 px-3">
            <div className="offset-lg-2 col-lg-8 col-md-12 form_cont text-center">
              <div className="row">
                <div className="form-group">
                  <div
                    className={`${
                      questions[currentQuestionIndex].final_question &&
                      "final-question-parentClass"
                    }`}
                  >
                    <h3 className={`slide-heading`} ref={autofocus}>
                      {questions[currentQuestionIndex].question}
                    </h3>
                    {questions[currentQuestionIndex].dummyText && (
                      <p>{questions[currentQuestionIndex].dummyText}</p>
                    )}
                    {/* {questions[currentQuestionIndex].dummyText1 && (
                  <p className={`dummyText1 ${questions[currentQuestionIndex].dummyText1 && "final-question-dummyText"}`}></p>
                )} */}
                    {questions[currentQuestionIndex].dummyText1 &&
                    activateCallBtn ? (
                      <p style={{ fontSize: 18 }}>
                        {questions[currentQuestionIndex].dummyText1}
                      </p>
                    ) : (
                      <></>
                    )}
                    {questions[currentQuestionIndex].dummyText2 &&
                    activateCallBtn ? (
                      <p>{questions[currentQuestionIndex].dummyText2}</p>
                      
                    ) : (
                      <></>
                    )}
                {questions[currentQuestionIndex].dummyText3 &&
                    activateCallBtn ? (
                      <p>{questions[currentQuestionIndex].dummyText3}</p>
                      
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    onClick={currentQuestionIndex !== 0 ? toggleFocus() : null}
                  ></div>
                  <div>
                    {questions[currentQuestionIndex].options.map(
                      (option, index) =>
                        questions[currentQuestionIndex].multiple ? (
                          <div
                            className="col-lg-12 offset-lg-2 text-start my-1 m-auto"
                            key={index}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`check${questions[currentQuestionIndex].id}`}
                                id={`checkId${option.id}`}
                                onChange={() =>
                                  clearErrors(
                                    `check${questions[currentQuestionIndex].id}`
                                  )
                                }
                                checked={
                                  selectedValues[
                                    questions[currentQuestionIndex].id
                                  ]?.includes(option.id) || false
                                }
                                {...register(
                                  `check${questions[currentQuestionIndex].id}`
                                )}
                                onClick={() => handleOptionClick(option.id)}
                              />
                              <label
                                htmlFor={`checkId${option.id}`}
                                className="form-check-label"
                              >
                                {option.text}
                              </label>
                            </div>
                          </div>
                        ) : option.specialcss === "CALL_CLICK" ? (
                          activateCallBtn ? (
                            <div className="col-12 mb-2" key={index}>
                              <a
                                className="buttonBounce CALL_CLICK btn my-bts text-white px-3"
                                onClick={() => handleOptionClick(option.id)}
                                href={`tel: ${phoneNumber}`}
                              >
                                <span>{option.text}</span>
                              </a>
                              <div className="chater-prof">
                                <div
                                  className="supporter-dp"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                ></div>
                                <h5
                                  className="text-msg btn-spc fs-6"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "17px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {/* <Time
                            timeLoad={showTime}
                            minuteLoad="1"
                        /> */}
                                  Your spot will remain reserved for:{" "}
                                  <span style={{ color: "red" }}>
                                    <Timer />
                                  </span>
                                </h5>
                              </div>
                            </div>
                          ) : (
                            <img
                              key={index}
                              src={loader_gif}
                              alt="Loader..."
                              style={{ width: "200px", margin: "auto" }}
                            />
                          )
                        ) : (
                          <div
                            className={`${
                              // questions[currentQuestionIndex].specialcss === 'verticleButton'
                              //   ? 'col-12 mb-2'
                              //   :
                              "col-12 mb-2"
                            }`}
                            key={index}
                          >
                            <button
                              className={`btn-comm ${
                                selectedValues[
                                  questions[currentQuestionIndex].id
                                ]?.includes(option.id) && "active"
                              }`}
                              onClick={() => handleOptionClick(option.id)}
                            >
                              {option.text}
                            </button>
                          </div>
                        )
                    )}
                  </div>
                  {questions[currentQuestionIndex].multiple && (
                    <>
                      {errors[`check${questions[currentQuestionIndex].id}`] && (
                        <span className="error_msg">
                          {
                            errors[`check${questions[currentQuestionIndex].id}`]
                              .message
                          }
                        </span>
                      )}
                      <div className="row">
                        <div className="pt-3 text-center">
                          <button
                            className="next-but btn flasher my-bts sctop1 next03"
                            onClick={() =>
                              handleOptionClick("multiple_continue")
                            }
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {currentQuestionIndex > 0 && (
                    <div className="col-12 text-center my-3">
                      <a
                        className="back_btn sctop"
                        onClick={() =>
                          setCurrentQuestionIndex((prevIndex) => prevIndex - 1)
                        }
                      >
                        <img src={arrow} alt="" /> Previous{" "}
                      </a>
                    </div>
                  )}
                  <div className="text-center safe-text mb-2">
                    <img
                      src={ico_safe}
                      alt="Safe, Secure &amp; Confidential"
                      width="12"
                      height="15"
                    />
                    Safe | Secure | Privacy Protected
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`col-lg-12 col-md-12 col-sm-12 px-3 form_cont ${
            !optionvalue && "hide"
          }`}
        >
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <div
              onClick={currentQuestionIndex !== 0 ? toggleFocus() : null}
            ></div>
            <h3 className="slide-heading">
              We're searching for the best policies for you!
            </h3>
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <div className="text-center para">
                  <h6 className="fw-bold">Who is this quote for?</h6>
                </div>
                <form className="form-group">
                  <div className="mb-3 text-start">
                    <SelectBox
                      OptionValue={Salutation}
                      className="form-control wid_title"
                      id="lstSalutation"
                      name="lstSalutation"
                      onChange={() => clearErrors("lstSalutation")}
                      register={register("lstSalutation", {
                        required: "Please Select Title",
                      })}
                      validationMsg={
                        errors.lstSalutation && errors.lstSalutation.message
                      }
                    />
                  </div>
                  <div className="mb-3 text-start">
                    <TextField
                      type="text"
                      className="form-control"
                      id="txtFName"
                      placeholder="First Name"
                      name="txtFName"
                      onChange={() => clearErrors("txtFName")}
                      register={register("txtFName", {
                        required: "Please Enter First Name",
                        minLength: {
                          value: 3,
                          message: "Please Enter Valid First Name",
                        },
                        pattern: {
                          value: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                          message: "Your Name is Invalid. Please Recheck",
                        },
                      })}
                      validationMsg={errors.txtFName && errors.txtFName.message}
                    />
                  </div>
                  <div className="mb-3 text-start">
                    <TextField
                      type="text"
                      className="form-control"
                      id="txtLName"
                      name="txtLName"
                      placeholder="Surname"
                      onChange={() => clearErrors("txtLName")}
                      register={register("txtLName", {
                        required: "Please Enter Last Name",
                        minLength: {
                          value: 3,
                          message: "Please Enter Valid Last Name",
                        },
                        pattern: {
                          value: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                          message: "Your Name is Invalid. Please Recheck",
                        },
                      })}
                      validationMsg={errors.txtLName && errors.txtLName.message}
                    />
                  </div>
                  <div className="text-center para">
                    <h6 className="fw-bold">
                      Where should we send your quote?
                    </h6>
                  </div>
                  <div className="mb-3 text-start">
                    <TextField
                      type="tel"
                      className="form-control"
                      id="txtPhone"
                      placeholder="Mobile Phone"
                      maxlength={11}
                      onChange={() => clearErrors("txtPhone")}
                      onBlur={(e) => phoneValidCheck(e)}
                      // register={register("txtPhone", {
                      //   pattern: {
                      //     value: /^[0-9]*$/i,
                      //     message: "Please Enter Valid Phone Number",
                      //   },
                      //   required: "Please Enter Phone Number",
                      // })}
                      validationMsg={errors.txtPhone && errors.txtPhone.message}
                    />
                  </div>
                  <div className="mb-3 text-start">
                    <TextField
                      type="email"
                      className="form-control"
                      id="txtEmail"
                      placeholder="Email Address"
                      onBlur={(e) => emailValidCheck(e)}
                      onChange={() => clearErrors("txtEmail")}
                      // register={register("txtEmail", {
                      //   required: "please enter your Email",
                      //   pattern: {
                      //     value: 3,
                      //     message: "please enter valid Email",
                      //   },
                      //   pattern: {
                      //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //     message: "Invalid Email Address",
                      //   },
                      // })}
                      validationMsg={errors.txtEmail && errors.txtEmail.message}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="pt-3 text-center">
              <button className="my-bts" onClick={onSubmit}>
                Submit
              </button>
              <div className="col-12 text-center my-2">
                <a
                  className="back_btn sctop"
                  onClick={
                    () => setoptionvalue("")
                    // , setCurrentQuestionIndex((prevIndex) => prevIndex - 1)
                  }
                >
                  <img src={arrow} alt="" /> Previous{" "}
                </a>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="col-lg-8 offset-lg-2 col-12 authorise text-center">
                  <p>
                    By continuing you will receive a call from an FCA authorised
                    life insurance consultant if we feel we can help you based
                    on the information you have provided. More details can be
                    found in our{" "}
                    <Link target="_blank" to="privacy" className="rp_clk">
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
              <div className="text-center safe-text pb-4">
                <img
                  src={ico_safe}
                  alt="Safe, Secure &amp; Confidential"
                  width="12"
                  height="15"
                />
                Safe | Secure | Privacy Protected
              </div>
            </div>
          </div>
        </div>
      </div>
      {unqlifiedModalBody && (
        <UnqualifiedModal
          unqlifiedModalBody={unqlifiedModalBody}
          setUnqlifiedModalBody={setUnqlifiedModalBody}
        />
      )}
    </>
  );
};

export default ChatBotBubble_OV50_GPOS_V4;
