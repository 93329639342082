import React, { useState } from 'react';
// import "../../../assets/css/Modal.scss";

const ThankyouModal = ({ modalBody, setModalBody, ModalViewers, showLoader, buttonText }) => {
  const firstName = localStorage.getItem("firstName") || "firstName";
  return (
    <>
      <div
        className={`modal fade unqlifiedmodal-pop show ${modalBody === '' ? 'd-hide' : 'd-block'}`}
        tabIndex={-1}
        role='dialog'
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}
      >
        <div
          className='modal-dialog modal-dialog-centered modal-md'
          role='document'>
          <div className='modal-content bor-b5'>
            <div className='modal-body'>
              <div className='unqualify-modal-p text-center fw-bold'>
                <div className="thanku">
                  <h5 className="modal-title mb-4 fw-bold">Thank You {firstName},</h5>
                  <h6>
                    One of our trusted Life Insurance advisors needs to speak with you on
                    the phone briefly to get a bit more information and help determine the
                    best option for you.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankyouModal;
