import React from 'react';
import { DobYear, DobMonth, DobDay } from '../Constants/Constants';
import SelectBox from '../Components/UI/SelectBox';


const DateOfBirthNew = ({ textDob, register, errors, className }) => {
    return (
        <div className="form-group col-12">
            <fieldset className="scheduler-border">
                {textDob && <legend className="scheduler-border">{textDob}</legend>}
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">
                        <SelectBox
                            className={`${className ? className : "form-select"}`}
                            OptionValue={DobDay}
                            name="DobDay"
                            id="DobDay"
                            register={register("DobDay", {
                                required: "Please Select Date"
                            })}
                            validationMsg={errors.DobDay && errors.DobDay.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">
                        <SelectBox
                            className={`${className ? className : "form-select"}`}
                            OptionValue={DobMonth}
                            name="DobMonth"
                            id="DobMonth"
                            register={register("DobMonth", {
                                required: "Please Select Month"
                            })}
                            validationMsg={errors.DobMonth && errors.DobMonth.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <SelectBox
                            className={`${className ? className : "form-select"}`}
                            OptionValue={DobYear}
                            name="DobYear"
                            id="DobYear"
                            register={register("DobYear", {
                                required: "Please Select Year"
                            })}
                            validationMsg={errors.DobYear && errors.DobYear.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                    </div>
                    {/* <span id="dob_final_err" className="error_msg"></span> */}
                </div>
            </fieldset>
        </div>
    )
}

export default DateOfBirthNew;