import React, { useState } from "react";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Cookiespolicy from '../Cookiespolicy';
import PrivacyPolicy from '../PrivacyPolicy';
import Terms from '../../../Pages/common/Terms';

const AdvFooter = () => {
  const [showCookie, setshowCookie] = useState('show');
  var cookieName = "CookiesPolicyHide";

  const [showprivacy, setshowprivacy] = useState(false);
  const [showpcookies, setshowpcookies] = useState(false);
  const [showTerms, setshowTerms] = useState(false);

  const handleprivacyShow=()=>{
    setshowprivacy(true);
  };
  const handleprivacyClose=()=>{
    setshowprivacy(false);
  };
  const handleCookieShow=()=>{
    setshowpcookies(true);
  };
  const handleCookieHide=()=>{
    setshowpcookies(false);
  };
  const handleTermsShow=()=>{
    setshowTerms(true);
  };
  const handleTermsHide=()=>{
    setshowTerms(false);
  };

  const cookieClose = (e) => {
    e.preventDefault();
    setshowCookie('hide');
    if (cookieName != null) {
      var d = new Date();
      var cvalue = "Yes";
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; ";
      setshowCookie('hide');
    }
  }
  return (
    <>
     <footer className="footer_text mt-0 pb-3 footer-bg">
    <div className="container footer-bottom">
      <div className="row text-center">
        <div className="col-md-12">
          <h6>Disclaimer</h6>
          <p>
            Stay Covered - Financial Services is a trading style of Veriform Ltd
            and is authorised and regulated by the FCA.
          </p>
          <p>
            Subject to our terms and conditions Stay Covered - Financial
            Services will process the information you provide us and may pass
            this on to our named partners for marketing purposes.
          </p>
          <p>
            Veriform Ltd is a company registered in England &amp; Wales,
            registration number 07195400 and registered at 145/147 Hatfield
            Road, St. Albans, Hertfordshire, AL1 4JY, Authorised and regulated
            by the Financial Conduct Authority, firm reference number 690199.
          </p>
          <p>
            Stay Covered - Financial Services is not an insurance company nor
            able to offer any advice into any financial services regulated
            products. Any terms and conditions may vary for all of our partners.
          </p>
          <p>
            We do not charge a fee for this service, but we do receive a
            commission directly from our partners following successful
            introductions from this website. Any information we provide is for
            the purposes of illustrating the products promoted on this site only
            and should not be read as financial advice.
          </p>
          <p>Stay Covered - Financial Services © 2024</p>
          <div>
          <p>
            <Link target="_blank" to="/privacy" className='text-dark'>Privacy Policy</Link>
            {" "}|{" "}
            <Link target="_blank" to="/terms" className='text-dark'>Terms and Conditions</Link>
          </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
      <div id="cookieBar" className={` cooki text-center ${showCookie}`}>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
            <span>Cookies help us deliver our services. By using this website you agree to use of our cookies. <a href="#" className="rp_clk" data-bs-toggle="modal" data-bs-target="#cookies" onClick={handleCookieShow}><u>Learn More.</u></a> </span>
            <a className="rp_clk closecook" href="#" onClick={cookieClose} data-cookiename="CookiesPolicyHide" id="cookclose">Close <strong>X</strong></a>
        </div>
        <div className="clearfix"></div>
      </div>

    </>
  )
}
export default AdvFooter;
