import React from 'react';
import AnchorAdv from "../../../UI/AnchorAdv";
import FbViewPixel from '../../../../Utility/FbViewPixel';
import logo from '../../../../assets/img/QSCV_ADV1/logo.png';
const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <header>
          <div className="container">
            <div className="row">
            <div className="row">
            <div className="col-lg-12 text-center advpara">
              <p>Advertorial</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 logo">
              <AnchorAdv dataId="SITE_lOGO" className="">
                {" "}
                <img src={logo} alt="" />{" "}
              </AnchorAdv>

            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
            <AnchorAdv dataId="TOP_CTA_BTN" className="get_btn btn btn-style">Get My Quotes</AnchorAdv>
            </div>
          </div>
            </div>
          </div>
      </header>
    </>
  )
}
export default AdvHeader;
