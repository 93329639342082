import React, { useEffect, useState } from 'react';

const Timer = () => {
  const [milliseconds, setMilliseconds] = useState(120000); // 2 minutes in milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      setMilliseconds(prevMilliseconds => {
        if (prevMilliseconds > 0) {
          return prevMilliseconds - 10;
        } else {
          return 120000; // Reset to 2 minutes
        }
      });
    }, 10);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const remainingMilliseconds = Math.floor((time % 1000) / 10); // Get the tens and hundreds place of milliseconds
    return `${minutes < 10 ? "0" : ""}${minutes}:` +
           `${seconds < 10 ? "0" : ""}${seconds}:` +
           `${remainingMilliseconds < 10 ? "0" : ""}${remainingMilliseconds}`;
  };

  return (
    <div className='time1 mt-1 fs-5 text-left text-danger fw-bold'>{formatTime(milliseconds)}</div>
  );
};

export default Timer;
