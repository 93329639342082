import React, { useEffect, useState, useRef, useContext } from "react";
import { QandA_OV50_CHBQS_V2_7_2B as QandA } from "../../../Constants/slideConstants/ChatbotConstant_OV50_CHBQS_V2_5";
// import SimpleModal from "./SimpleModal";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import loader from "../../../assets/img/OV50_CHBQS_V2_5/loader.gif";
import avatar2 from "../../../assets/img/OV50_CHBQS_V2_5/avatar2.png";
import avatar from "../../../assets/img/OV50_CHBQS_V2_5/avatar.png";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import TextField from "../../UI/TextField";
import TelephoneTextField from "../../UI/TelephoneTextField ";
import ThankyouModal from "./ThankyouModal";
import UnqualifiedModal from "../../Includes/Layouts/UnqualifiedModal";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import Email from "../../UI/Email";
import GTMDataLayer from "../common/GTMDataLayer";
import Timer from "./Timer";
// import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";

const ChatBotBubble_OV50_CHBQS_V2_7_2B = ({
  formSubmit,
  journeyStatus,
  saveUser,
  uuid,
  splitName,

  is_online,
  buttonRedirect,
  phoneNumber,
  formattedPhoneNumber,
  autofocus,
}) => {
  const {
    trigger,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [phoneValid, setPhoneValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [renderOption, setRenderOption] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [displayDelay, setDisplayDelay] = useState(2000); // Delay for questions
  const [modalBody, setModalBody] = useState("");
  const [unqlifiedModalBody, setUnqlifiedModalBody] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const { saveDataIngestion } = useDataIngestion();
  const [showContinue, setShowContinue] = useState(false);
  const history = useHistory();
  const questions = QandA;
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [showTime, setShowTime] = useState(false);

  //! /////// for setting dynamic custom values /////////
  let saveAsArray = true; // change value as needed
  const isMultiple = true; // change value as needed
  if (isMultiple === true) saveAsArray = true;

  //* conditons can be set here based on questions or options (for unqualified popup)
  const conditions = [
    // { questionId: 'sampleID', optionIds: ['sampleIDs','sampleIDs','sampleIDs'] },
  ];

  //* set conditions to skip question index based on question id and option ids
  const indexSkipConditions = {
    // '1': {
    //   specificOptionIds: ['2'],
    //   actions: {
    //     '2': () => setUnqlifiedModalBody("Sorry, we are not able to help if your age is below 50"),
    //      // you can add more option conditions here
    //   },
    // },
    //* Add more conditions for other 'currentQuestion' values here
  };
  //! /////// END /////////

  useEffect(() => {
    if (uuid) {
      journeyStatus({
        slide_type: "question",
        slide_question:
          questions[0].first_question === true ? questions[0].id : "",
        slide_value: "",
        type: "view",
      });
    }

    const query = new URLSearchParams(window.location.search);
    const devcommand = query.get("devcommand");
    if (devcommand === "fast") {
      setDisplayDelay(0);
    }
  }, [uuid]);

  const ModalViewers = async (click_status) => {
    setShowLoader(true);
    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      splitName
    );
    // window.location = UnqualifiedRedirectionLinkSpanish(questions[currentQuestionIndex].options.find((opt) => opt.text === selectedOption).id).redirectionLink;
  };

  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      splitName
    );
    journeyStatus({
      slide_type: "button",
      slide_question: "CALL_CLICK",
      slide_value: "",
      type: "click",
    });
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };

  const handleOptionSelect = (option) => {
    //* Create a copy of the chat history with the user's response and the selected option.
    const updatedChatHistory = [...chatHistory];
    //* Pass question id and option id to a function for sending to the backend.
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    let optionId;
    if (option == "continue") {
      gtmDataLayer(questionId, option);
    }

    if (option !== "continue") {
      if (questionId != "10") {
        gtmDataLayer(questionId, option);
      }
      optionId = selectedQuestion.options.find((opt) => opt.text === option).id;
    }

    if (
      questionId === "8" &&
      optionId === "19" &&
      optionId === "20" &&
      optionId === "21" &&
      optionId === "22"
    ) {
      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });
      return 0;
    }
    if (questionId === "9" && optionId === "24") {
      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });
      setUnqlifiedModalBody(
        "Sorry, we are not able to help if your age is below 50"
      );
      return 0;
    }

    function checkConditions(questionId, optionId) {
      for (const condition of conditions) {
        if (
          questionId === condition.questionId &&
          condition.optionIds.includes(optionId)
        ) {
          return true;
        }
      }
      return false;
    }

    if (questionId === "CALL_CLICK" || optionId === "17") {
      journeyStatus({
        slide_type: "button",
        slide_question: "CALL_CLICK",
        slide_value: "",
        type: "click",
      });
      //
      // journeyStatus({
      //   slide_type: 'question',
      //   slide_question: questionId,
      //   slide_value: optionId,
      //   type: 'click',
      // });
      formSubmit(JSON.parse(localStorage.getItem("selectedOptions")));
      saveUser("CALL_CLICK", 1);
      return;
    }
    // this section is to check condition and open redirect popup
    else if (checkConditions(questionId, optionId)) {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question_in_group === questionId) {
          message.last_question_in_group = true;
        }
      });

      setModalBody(
        "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳"
      );

      return false;
    } else {
      if (option !== "continue") {
        if (saveAsArray !== true) {
          const storedOptions = localStorage.getItem("selectedOptions");
          const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
          parsedOptions["question_" + questionId] = optionId;
          localStorage.setItem(
            "selectedOptions",
            JSON.stringify(parsedOptions)
          );
        } else {
          const storedOptions = localStorage.getItem("selectedOptions");
          const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
          const existingOptions = parsedOptions["question_" + questionId] || [];
          // Check if the optionId is already present
          const optionIndex = existingOptions.indexOf(optionId);
          if (optionIndex === -1) {
            // Add the optionId if not present
            existingOptions.push(optionId);
          } else {
            // Remove the optionId if already present
            existingOptions.splice(optionIndex, 1);
          }
          parsedOptions["question_" + questionId] = existingOptions;
          localStorage.setItem(
            "selectedOptions",
            JSON.stringify(parsedOptions)
          );

          if (isMultiple === true) {
            // Check if any option values are present
            const hasOptions = existingOptions.length > 0;
            // Set showContinue based on the condition
            if (hasOptions) {
              setShowContinue(true);
            } else {
              setShowContinue(false);
            }
          }
        }
      }

      if (
        !selectedQuestion ||
        !selectedQuestion.multiple ||
        option === "continue"
      ) {
        let selectedOptionsText;
        if (option === "continue") {
          const storedOptions = localStorage.getItem("selectedOptions");
          const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
          const existingOptions = parsedOptions["question_" + questionId] || [];
          // Find the text corresponding to the option ID
          selectedOptionsText = existingOptions
            .map((optId) => {
              const optionObject = selectedQuestion.options.find(
                (option) => option.id === optId
              );
              return optionObject ? optionObject.text : "";
            })
            .join("|||");
        }

        updatedChatHistory.push({
          role: option === "continue" ? "user_multiple" : "user",
          text: option === "continue" ? selectedOptionsText : option,
        });

        setChatHistory(updatedChatHistory);
        setSelectedOption(option);

        if (!selectedQuestion.multiple) {
          toggleFocus(questionId);
        }
        // for showing the bot DP after clicking the option by adding last_question_in_group as true
        updatedChatHistory.forEach((message) => {
          if (message.last_question_in_group === questionId) {
            message.last_question_in_group = true;
          }
        });
      }

      if (option === "continue" && selectedQuestion.multiple) {
        const storedOptions = localStorage.getItem("selectedOptions");
        const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
        const existingOptions = parsedOptions["question_" + questionId] || [];
        const firstOptionId = existingOptions[0]; // Get the first option ID
        journeyStatus({
          slide_type: questionId === "CALL_CLICK" ? "button" : "question",
          slide_question: questionId,
          slide_value: firstOptionId,
          type: "click",
        });
      } else if (option !== "continue" && !selectedQuestion.multiple) {
        journeyStatus({
          slide_type: questionId === "CALL_CLICK" ? "button" : "question",
          slide_question: questionId,
          slide_value: optionId,
          type: "click",
        });
      }

      // Here, you can call a function to send questionId and optionId to the backend.
      if (questions[currentQuestionIndex].final_question === true) {
        formSubmit(JSON.parse(localStorage.getItem("selectedOptions")));
      }
    }

    // Move to the next question or end the conversation when all questions are answered. (2)
    function handleSpecificConditions(
      currentQuestionId,
      specificOptionIds,
      actions
    ) {
      const storedOptions = localStorage.getItem("selectedOptions");
      const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
      const selectedQuestionOptions =
        parsedOptions["question_" + currentQuestionId] || [];

      const foundOption = specificOptionIds.find((optionId) =>
        selectedQuestionOptions.includes(optionId)
      );
      if (foundOption) {
        actions[foundOption]();
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }

      setRenderOption(false);
      setSelectedOption("");
      setShowContinue(false);
    }

    // Move to the next question or end the conversation when all questions are answered. (1)
    if (
      !selectedQuestion ||
      !selectedQuestion.multiple ||
      option === "continue"
    ) {
      if (currentQuestionIndex + 1 < questions.length) {
        const currentQuestion = questions[currentQuestionIndex].id;

        function handleIndexConditions(currentQuestion) {
          const condition = indexSkipConditions[currentQuestion];

          if (condition) {
            const { specificOptionIds, actions } = condition;
            handleSpecificConditions(
              currentQuestion,
              specificOptionIds,
              actions
            );
          } else {
            // Continue with the default behavior for other questions
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setRenderOption(false);
            setSelectedOption("");
            setShowContinue(false);
          }
        }
        handleIndexConditions(currentQuestion);
      } else {
        // Conversation ended. You can handle this as needed.
        // For example, display a closing message.
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("selectedOptions");
  }, []);

  useEffect(() => {
    const questionDelay = displayDelay; // Always apply the delay.
    const segment = questions[currentQuestionIndex];
    let currentIndex = 0; // Index for the current question within the segment.

    let qno = 0;

    // Function to display the next question within the segment.
    const displayNextQuestion = () => {
      if (currentIndex < segment.question.length) {
        const currentQuestion = segment.question[currentIndex];
        if (currentQuestion) {
          setChatHistory((prevChat) => [
            ...prevChat,
            { role: "bot", text: "Loading" },
          ]);
          setTimeout(() => {
            setChatHistory((prevChat) => {
              const updatedChat = [...prevChat];
              updatedChat.pop(); // Remove the last message, which is "Loading..."

              // Check if it's the last question in the group
              qno++;
              const isLastQuestion_inGroup =
                questions[currentQuestionIndex].options.length === 0 &&
                qno === segment.question.length
                  ? true
                  : qno === segment.question.length
                  ? segment.id
                  : false;

              const newMessage = {
                role: "bot",
                text: currentQuestion,
                last_question_in_group: isLastQuestion_inGroup,
              };

              if (
                isLastQuestion_inGroup !== false &&
                uuid !== "" &&
                segment.first_question !== true &&
                questions[currentQuestionIndex].id !== "" &&
                questions[currentQuestionIndex].id !== "7"
              ) {
                journeyStatus({
                  slide_type:
                    questions[currentQuestionIndex].id === "CALL_CLICK"
                      ? "button"
                      : "question",
                  slide_question: segment.id,
                  slide_value: "",
                  type: "view",
                });
              }

              if (
                isLastQuestion_inGroup !== false &&
                questions[currentQuestionIndex].id === "7"
              ) {
                journeyStatus({
                  slide_type: "button",
                  slide_question: "CALL_CLICK",
                  slide_value: "",
                  type: "view",
                });
              }

              return [...updatedChat, newMessage];
            });

            if (currentIndex === segment.question.length) {
              // Display options after all questions in the segment.
              if (segment.options && segment.options.length > 0) {
                // this if is for displaying loader before showing CALL_CLICK
                if (segment.options[0].specialcss === "CALL_CLICK") {
                  setShowTime(true);
                  setChatHistory((prevChat) => [
                    ...prevChat,
                    { role: "bot", text: "Loading" },
                  ]);
                  setTimeout(() => {
                    setChatHistory((prevChat) => {
                      const updatedChat = [...prevChat];
                      updatedChat.pop();
                      return updatedChat;
                    });
                    setRenderOption(true);
                  }, displayDelay);
                } else {
                  setRenderOption(true);
                }
              }
            }
          }, displayDelay);
        }

        currentIndex++; // Move to the next question.
        setTimeout(
          displayNextQuestion,
          currentIndex === segment.question.length ? 0 : questionDelay
        ); // Apply the delay only after the last question.
      }
    };

    // Automatically initiate the conversation with the first question.
    const timer = setTimeout(displayNextQuestion, questionDelay);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex, displayDelay, questions]);

  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset =
        questions[currentQuestionIndex].specialcss === "verticleButton" ||
        questions[currentQuestionIndex].specialcss === "form1" ||
        questions[currentQuestionIndex].specialcss === "form2"
          ? -200
          : -445;
      const scrollPosition =
        refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      window.scroll(0, 0);
    }
  };

  const { phoneValidation } = usePhoneValidation();
  const { emailValidation } = useEmailValidation();
  const onSubmit = async (data) => {
    const { txtEmail } = getValues();
    if (txtEmail !== "") {
      const getEmailValidation = await emailValidation(txtEmail, uuid);
      if (getEmailValidation.data.status_code == 1) {
        clearErrors("txtEmail");
      } else {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
        return;
      }
    }
    if (txtEmail == "") {
      setError("txtEmail", {
        type: "manual",
        message: "Enter Email address",
      });
      return;
    }
    // Retrieve existing form data from local storage
    const storedFormData =
      JSON.parse(localStorage.getItem("selectedOptions")) || {};
    //const txtEmail = document.getElementById('txtEmail').value;
    const updatedFormData = {
      txtEmail: txtEmail,
      ...storedFormData,
      page_name: splitName,
    };
    GTMDataLayer({
      question: "email",
      answer: "",
    });

    journeyStatus({
      slide_type: "question",
      slide_question: "email",
      slide_value: "",
      type: "click",
    });
    // Save updated form data back to local storage
    localStorage.setItem("selectedOptions", JSON.stringify(updatedFormData));
    const queryString = "";
    const formSubmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      updatedFormData,
      "split_form_submit",
      splitName,
      queryString,
      visitorParameters.data
      // Pass page_name field here
    );

    history.push("/thankyou?uuid=" + uuid);
  };
  const emailValidCheck = async (e) => {
    const values = getValues();
    // const email = values['txtEmail'];
    const email = e.target.value;
    const txtEmail = await trigger("txtEmail");
    if (txtEmail) {
      const getEmailValidation = await emailValidation(email, uuid);
      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
        setEmailValid(false);
        return 0;
      } else {
        clearErrors("txtEmail");
        setEmailValid(true);
        return 1;
      }
    }
  };

  const NextButtonClick = async () => {
    const { txtFName, txtLName } = getValues();

    const txtFName_trigger = await trigger("txtFName");
    const txtLName_trigger = await trigger("txtLName");

    if (!txtFName_trigger && !txtLName_trigger) {
      return;
    }
    if (!txtFName_trigger || !txtLName_trigger) {
      return;
    }

    const updatedChatHistory = [...chatHistory];
    updatedChatHistory.push({
      role: "user",
      text: txtFName + " " + txtLName,
    });

    const storedFormData =
      JSON.parse(localStorage.getItem("selectedOptions")) || {};
    const updatedFormData = {
      ...storedFormData,
      txtFName: txtFName,
      txtLName: txtLName,
    };
    localStorage.setItem("firstName", txtFName);
    localStorage.setItem("selectedOptions", JSON.stringify(updatedFormData));
    GTMDataLayer({
      question: "name",
      answer: "",
    });
    journeyStatus({
      slide_type: "question",
      slide_question: "basic_info",
      slide_value: "",
      type: "click",
    });

    setChatHistory(updatedChatHistory);
    setRenderOption(false);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const phoneNumberClick = async () => {
    const { txtPhone } = getValues();

    if (txtPhone !== "") {
      const getPhoneValidation = await phoneValidation(txtPhone);
      if (getPhoneValidation.data.status_code === 1) {
        clearErrors("txtPhone");
      } else {
        setError("txtPhone", {
          type: "manual",
          message: getPhoneValidation.data.message,
        });
        return;
      }
    } else {
      setError("txtPhone", {
        type: "manual",
        message: "Please enter phone number",
      });
      return;
    }
    const txtPhone_trigger = await trigger("txtPhone");
    if (!txtPhone_trigger) {
      return;
    }
    const updatedChatHistory = [...chatHistory];
    updatedChatHistory.push({
      role: "user",
      text: txtPhone,
    });
    GTMDataLayer({
      question: "phone number",
      answer: "",
    });
    journeyStatus({
      slide_type: "question",
      slide_question: "phone_number",
      slide_value: "",
      type: "click",
    });

    const storedFormData =
      JSON.parse(localStorage.getItem("selectedOptions")) || {};
    const updatedFormData = {
      ...storedFormData,
      txtPhone: txtPhone,
    };
    localStorage.setItem("selectedOptions", JSON.stringify(updatedFormData));

    setChatHistory(updatedChatHistory);
    setRenderOption(false);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const gtmDataLayer = (current_question, element) => {
    let question = "";
    let answer = "";

    switch (current_question) {
      case "1":
        question = "born year";
        break;
      case "5":
        question = "insuring for";
        break;
      case "6":
        question = "looking for insurance";
        break;
      case "7":
        question = "ringba call";
        break;
    }
    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  return (
    <div>
      {chatHistory.map((message, index) =>
        message.role === "bot" ? (
          <div key={index} className="chater-prof">
            <div
              onClick={
                toggleFocus()
              }
            ></div>
            <div>
              {message.text === "Loading" ||
              message.last_question_in_group === true ? (
                <div className="supporter-dp">
                  <img src={avatar} alt="" />
                </div>
              ) : (
                <div
                  className="supporter-dp"
                  style={{ backgroundColor: "transparent", border: "none" }}
                ></div>
              )}
            </div>
            <div>
              {message.text === "Loading" ? (
                <div className="msg-loader loader1">
                  <div className="text-msg">
                    <img src={loader} alt="" />
                  </div>
                </div>
              ) : (
                <div className="mesg1">
                    <span
                      className={`text-msg text-message-item slide-top ${message.unique ? message.unique : ""
                        }`}
                    >
                      {message.text.includes("{li}") ? (
                        <ul>
                          {message.text.split("{li}").map((part, index) => (
                            <React.Fragment key={index}>
                              {part.trim() && <li style={{marginLeft:"15px"}}>{part.trim()}</li>}
                            </React.Fragment>
                          ))}
                        </ul>
                      ) : (
                        message.text
                      )}
                    </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div key={index} className="client-replay my-3">
            {/* <div onClick={questions[currentQuestionIndex].multiple ? null : toggleFocus()}></div> */}
            <div className="client-replay-vpx custom-width">
              <div className="sel-option">
                {/* {message.role === 'user_multiple' ? ( */}
                {message.text.split("|||").map((text, i) => (
                  <span
                    key={i}
                    className="action-btn d-block text-message-item rounded answ-bnt my-2"
                  >
                    {text}
                  </span>
                ))}
                {/* ) : (
                  <span className='action-btn d-block text-message-item rounded answ-bnt my-2'>
                    {message.text}
                  </span>
                )} */}
              </div>
              <div className="dp_client_h100">
                <div className="dp-client">
                  <img src={avatar2} alt="" />
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <div ref={autofocus}></div>
      {!selectedOption && (
        <>
          {questions[currentQuestionIndex].options &&
          questions[currentQuestionIndex].options.length > 0 &&
          renderOption === true ? (
            <>
              <div className="chater-prof">
                <div className="supporter-dp">
                  <img src={avatar} alt="" />
                </div>
                <div className="mesg4 sel-option">
                  <div className="text-msg btn-spc text-center">
                    {questions[currentQuestionIndex].options.map(
                      (option, index) =>
                        questions[currentQuestionIndex].specialcss ===
                        "form3" ? (
                          <div key={index} className="contact_dt">
                            <div className="qs-inner2 py-lg-2 py-md-4 py-sm-2">
                              <form className="px-3">
                                <div className="mb-3 text-start">
                                  <label
                                    htmlFor="txtPhone"
                                    className="form-label"
                                  >
                                    Email
                                  </label>
                                  <Email
                                    type="email"
                                    className="form-control"
                                    id="txtEmail"
                                    maxlength={11}
                                    onChange={() => clearErrors("txtEmail")}
                                    //onBlur={(e) => emailValidCheck(e)}
                                    register={register("txtEmail", {
                                      pattern: {
                                        value:
                                          /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: "Please Enter Valid Email",
                                      },
                                      required:
                                        "Please Enter Your Email Address",
                                    })}
                                    validationMsg={
                                      errors.txtEmail && errors.txtEmail.message
                                    }
                                  />
                                </div>
                                <div className="pt-3 text-center">
                                  <button
                                    className="call-btn regNextBtn action-btn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onSubmit();
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        ) : questions[currentQuestionIndex].specialcss ===
                          "form1" ? (
                          <div key={index} className="contact_dt">
                            <div className="qs-inner1 py-lg-4 py-md-4 py-sm-2">
                              {/* <h2 class="qs-text">Contact Details</h2> */}
                              <form className="px-3">
                                <div className="mb-3 text-start">
                                  <label
                                    htmlFor="txtFName"
                                    className="form-label"
                                  >
                                    First Name
                                  </label>
                                  <TextField
                                    type="text"
                                    className="form-control"
                                    id="txtFName"
                                    name="txtFName"
                                    onChange={() => clearErrors("txtFName")}
                                    register={register("txtFName", {
                                      required: "Please Enter First Name",
                                      minLength: {
                                        value: 3,
                                        message:
                                          "Please Enter Valid First Name",
                                      },
                                      pattern: {
                                        value: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                                        message:
                                          "Your Name is Invalid. Please Recheck",
                                      },
                                    })}
                                    validationMsg={
                                      errors.txtFName && errors.txtFName.message
                                    }
                                  />
                                </div>
                                <div className="mb-3 text-start">
                                  <label
                                    htmlFor="txtLName"
                                    className="form-label"
                                  >
                                    Last Name
                                  </label>
                                  <TextField
                                    type="text"
                                    className="form-control"
                                    id="txtLName"
                                    name="txtLName"
                                    onChange={() => clearErrors("txtLName")}
                                    register={register("txtLName", {
                                      required: "Please Enter Last Name",
                                      minLength: {
                                        value: 3,
                                        message: "Please Enter Valid Last Name",
                                      },
                                      pattern: {
                                        value: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
                                        message:
                                          "Your Name is Invalid. Please Recheck",
                                      },
                                    })}
                                    validationMsg={
                                      errors.txtLName && errors.txtLName.message
                                    }
                                  />
                                </div>
                              </form>
                              <div className="pt-3 text-center">
                                <button
                                  className="call-btn regNextBtn action-btn next"
                                  onClick={NextButtonClick}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : questions[currentQuestionIndex].specialcss ===
                          "form2" ? (
                          <div key={index} className="contact_dt">
                            <div className="qs-inner2 py-lg-2 py-md-4 py-sm-2">
                              <form className="px-3">
                                <div className="mb-3 text-start">
                                  <label
                                    htmlFor="txtPhone"
                                    className="form-label"
                                  >
                                    Phone Number
                                  </label>
                                  <TelephoneTextField
                                    type="tel"
                                    className="form-control"
                                    id="txtPhone"
                                    maxlength={11}
                                    onChange={() => clearErrors("txtPhone")}
                                    // onBlur={(e) => phoneValidCheck(e)}
                                    register={register("txtPhone", {
                                      pattern: {
                                        value: /^[0-9]*$/i,
                                        message:
                                          "Please Enter Valid Phone Number",
                                      },
                                      required: "Please Enter Phone Number",
                                    })}
                                    validationMsg={
                                      errors.txtPhone && errors.txtPhone.message
                                    }
                                  />
                                </div>
                              </form>
                              <div className="pt-3 text-center">
                                <button
                                  className="call-btn regNextBtn action-btn"
                                  onClick={phoneNumberClick}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : questions[currentQuestionIndex].multiple ===
                          true ? (
                          <div key={index}>
                            <div className="mb-1">
                              <div className=" form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={"option_" + option.id}
                                  onClick={() =>
                                    handleOptionSelect(option.text)
                                  }
                                />
                                <label
                                  className={`form-check-label ${
                                    option.text === "Other" &&
                                    " label_adj label_adj2"
                                  } ${
                                    option.text ===
                                      "Leave financial security for my family" &&
                                    " label_adj"
                                  }`}
                                  htmlFor={"option_" + option.id}
                                >
                                  {option.text}
                                </label>
                              </div>
                            </div>
                            {index ===
                              questions[currentQuestionIndex].options.length -
                                1 &&
                              showContinue && (
                                <div className="mt-4">
                                  <span
                                    className={`action-btn text-center action-btn-label text-message-item medibutton d-block curs_pointer`}
                                    onClick={() =>
                                      handleOptionSelect("continue")
                                    }
                                  >
                                    Continue
                                  </span>
                                </div>
                              )}
                          </div>
                        ) : questions[currentQuestionIndex].id ==
                          "CALL_CLICK" ? (
                          is_online == "online" ? (
                            <a
                              key={index}
                              className="action-btn text-message-item jobBtn mx-1 text-decoration-none"
                              onClick={() => {
                                handleOptionSelect(option.text);
                              }}
                              href={`tel: ${phoneNumber}`}
                            >
                              <span className="call icon_option_num icon_option_num_a text-white">
                                {formattedPhoneNumber}
                              </span>
                            </a>
                          ) : (
                            <div>
                              <a
                                key={index}
                                className="action-btn text-message-item jobBtn mx-1 text-decoration-none"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleRedirect(1, "call_offline");
                                }}
                                target="blank"
                              >
                                <span className="call icon_option_num icon_option_num_a text-white">
                                  Reclamar Beneficios
                                </span>
                              </a>
                            </div>
                          )
                        ) : option.specialcss === "CALL_CLICK" ? (
                          <div>
                            <a
                              key={index}
                              className="action-btn text-message-item me-1 text text-decoration-none"
                              onClick={() => handleOptionSelect(option.text)}
                              href={`tel: ${phoneNumber}`}
                            >
                              {option.text}
                            </a>
                          </div>
                        ) : (
                          <span
                            key={index}
                            className={`action-btn text-message-item
                            ${
                              questions[currentQuestionIndex].specialcss ===
                              "verticleButton"
                                ? " w-100 d-block mt-2"
                                : " me-1"
                            }`}
                            onClick={() => handleOptionSelect(option.text)}
                          >
                            {option.text}
                          </span>
                        )
                    )}
                  </div>
                </div>
              </div>
              {showTime && (
                  <div className="chater-prof">
                    <div className="supporter-dp" style={{backgroundColor:"transparent",border:"none"}}>
                    </div>
                    <h5 className="text-msg btn-spc fs-6" style={{fontWeight:"bold",fontSize:"17px",backgroundColor:"transparent"}}>
                      {/* <Time
                        timeLoad={showTime}
                        minuteLoad="1"
                      /> */}
                      Your spot will remain reserved for: <span style={{color:"red"}}>
                        <Timer/>
                      </span>
                    </h5>
                  </div>
                )}
            </>
          ) : null}
        </>
      )}

      <ThankyouModal modalBody={modalBody} />
      <UnqualifiedModal
        unqlifiedModalBody={unqlifiedModalBody}
        setUnqlifiedModalBody={setUnqlifiedModalBody}
      />
    </div>
  );
};

export default ChatBotBubble_OV50_CHBQS_V2_7_2B;
