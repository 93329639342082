import React, { useState } from 'react';

const CookieModal = ({ cookieModal, setCookieModal }) => {
  return (
    <>
      {/* ========= Terms And Conditions Start ========= */}
      <div className={`modal fade show ${cookieModal ? "d-block" : "d-none"}`}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Cookies
              </h4>
              <button type="button" className="ms-2 close" onClick={() => setCookieModal(false)}>
                ×
              </button>
            </div>
            <div className="modal-body">
              {/* <h4> COOKIE POLICY </h4> */}
              <p className="p3">
                <strong>
                  <span className="c0">Cookie Policy</span>
                </strong>
              </p>
              <p>
                This website uses cookies to better the users experience while
                visiting the website. Where applicable this website uses a cookie
                control system allowing the user on their first visit to the website
                to allow or disallow the use of cookies on their computer / device.
                This complies with recent legislation requirements for website’s to
                obtain explicit consent from users before leaving behind or reading
                files such as cookies on a users computer / device.
              </p>
              <p>
                Cookies are small files saved to the users computers hard drive that
                track, save and store information about the users interactions and
                usage of the website. This allows the website, through it’s server
                to provide the users with a tailored experience within this website.
              </p>
              <p>
                Users are advised that if they wish to deny the use and saving of
                cookies from this website on to their computers hard drive they
                should take necessary steps within their web browsers security
                settings to block all cookies from this website and it’s external
                serving vendors.
              </p>
              <p>
                This website uses tracking software to monitor it’s visitors to
                better understand how they use it. This software is provided by
                Google Analytics which uses cookies to track visitor usage. The
                software will save a cookie to your computers hard drive in order to
                track and monitor your engagement and usage of the website but will
                not store, save or collect personal information. You can read
                Google’s privacy policy here for further information
                [http://www.google.com/privacy.html].
              </p>
              <p>
                Other cookies may be stored to your computers hard drive by external
                vendors when this website uses referral programs, sponsored links or
                adverts. Such cookies are used for conversion and referral tracking
                and typically expire after 30 days, though some may take longer. No
                personal information is stored, saved or collected.
              </p>
              {/* <h3> HOW WE USE COOKIES</h3> */}
              <p className="p3">
                <strong>
                  <span className="c0">How We Use Cookies</span>
                </strong>
              </p>
              <p>
                A cookie is a small file which asks permission to be placed on your
                computer’s hard drive. Once you agree, the file is added and the
                cookie helps analyse web traffic or lets you know when you visit a
                particular site. Cookies allow web applications to respond to you as
                an individual. The web application can tailor its operations to your
                needs, likes and dislikes by gathering and remembering information
                about your preferences.
              </p>
              <p>
                We use traffic log cookies to identify which pages are being used.
                This helps us analyse data about web page traffic and improve our
                website in order to tailor it to customer needs. We only use this
                information for statistical analysis purposes and then the data is
                removed from the system.
              </p>
              <p>
                Overall, cookies help us provide you with a better website, by
                enabling us to monitor which pages you find useful and which you do
                not. A cookie in no way gives us access to your computer or any
                information about you, other than the data you choose to share with
                us.{" "}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => setCookieModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieModal;
