import React from 'react'
import "../../../assets/css/UnqlifiedModal.scss";

const UnqualifiedModal = ({ unqlifiedModalBody, setUnqlifiedModalBody }) => {
  return (
    <div
      className={`modal fade unqlifiedmodal-pop show ${unqlifiedModalBody === '' ? 'd-hide' : 'd-block'}`}
      tabIndex={-1}
      role='dialog'
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}
    >
      <div
        className='modal-dialog modal-dialog-centered modal-md'
        role='document'>
        <div className='modal-content bor-b5'>
          <div className='modal-body pb-0'>
            <div className='unqualify-modal-p text-center fw-bold'>
              <div className="thanku py-2 px-4">
                <h6>
                  {unqlifiedModalBody}
                </h6>
              </div>
            </div>
          </div>
          <div className='modal-footer justify-content-center mb-3'>
            <button
              type='button'
              className='btn btn-secondary btn-anime btn_reClaim'
              data-dismiss='modal'
              onClick={() => (setUnqlifiedModalBody(""))}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnqualifiedModal