import React, { useEffect, useState } from "react";
import { useUserClicks } from "../../../../Hooks/useUserClicks";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import { useContext } from "react";
import { queryString } from "../../../../Utility/QueryString";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import useJourneyStats from "../../../../Hooks/useJourneyStats";

const CallButton_OV50_CHBQS_V2_7_2C = ({ callClick, text, btnstyle, formattedPhoneNumber, phoneNumber, splitName }) => {
    const { saveUserClicks, isLoading } = useUserClicks();
    const { saveDataIngestion } = useDataIngestion();
    const saveJournyStats = useJourneyStats();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    // const splitName = localStorage.getItem("split_name");
    const handleClick = async (e, name) => {
        callClick();
        const clickSubmit = await saveUserClicks(
            uuid,
            name,
            1
        );
        await saveJournyStats(
            uuid,
            "top_button",
            "call_click_top_banner",
            "",
            "click",
            splitName
        );
        const formData = [];
        formData.lstSalutation = '';
        formData.txtFName = '';
        formData.txtLName = '';
        formData.txtEmail = '';
        formData.txtPhone = '';
        formData.page_name = 'OV50_CHBQS_V2_7_2C';
        const pixelSubmit = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "pixel_message",
            "OV50_CHBQS_V2_7_2C",
            queryString,
            visitorParameters.data,
        )
    }

    useEffect(() => {
        if (uuid) {
            async function journey() {
                await saveJournyStats(
                    uuid,
                    "top_button",
                    "call_click_top_banner",
                    "",
                    "view",
                    splitName
                );
            }
            journey()
        }
    }, [uuid])

    return (
        <>
            <a className="" onClick={(e) => handleClick(e, "call_click_top_banner")} href={`tel: ${phoneNumber}`}><button className={`button btn-full btnthird1 button-lg main-heading-button buttonBounce custom_callBtn btn-vp ${btnstyle}`} >{text}</button></a>
        </>
    )
}
export default CallButton_OV50_CHBQS_V2_7_2C;