export const SplitFaq_OV50_CHBQS_V2_7 = [
  {
    heading: 'When will my Over 50s Life Cover policy pay out?',
    content:
      `<p>This policy is designed to pay out when you die. But you need to have your policy in place for a minimum amount of time before you’re fully covered. This is called your qualifying period.</p>
      <p>Our standard qualifying period is two years. Answering the medical questions we ask could change this to just six months.</p>
      <p>If you decide to buy Over 50s Life Cover, your qualifying period will be confirmed on your Policy Schedule.</p>`,
  },
  {
    heading: 'How much does Over 50s Life Cover cost?',
    content:
      `<p>You can choose how much you’d like your monthly payments to be but this will affect how much your payout will be.</p>
      <p>Other factors like your age and whether you answer the medical questions will also be factored in to your payout amount.</p>`,
  },
  {
    heading: 'Do I have to answer any medical questions?',
    content:
      `<p>You can choose to answer our two medical questions or not.</p>
      <p>If you don’t answer them, you’ll be offered a two-year qualifying period (the time your policy has to be in place before you're covered).</p>
      <p>If you choose to answer them, you might be offered an increased payout and only a six-month qualifying period.</p>`,
  },
  {
    heading: 'How long will I have to pay for this policy?',
    content:
      `<p>To maintain cover you’ll have to make the payments for your policy until you die. Depending on how long you live, you could end up paying more than your payout.</p>`,
  },
  {
    heading: 'Who will my payout go to?',
    content:
      `<p>You can choose who will get your payout when you die. It’s important to do this in advance by making specific arrangements.</p>
      <p>If you don’t choose who the money will go to, it’ll form part of what’s known as your estate. That means it will be counted as one of the things you owned.</p>
      <p>Your estate could be subject to inheritance tax and/or delayed in being paid out to your loved ones. That's because wills, probate and letters of administration can take a while to process.</p>
      <p>Making a nomination or putting your policy in trust are ways to makes sure your loved ones can get the money a little quicker.</p>
      <p>If you're diagnosed with a terminal illness after your qualifying period, you can choose to get half of your payout. We'll then pay the other half when you die.</p>`,
  },
  {
    heading: 'How can I make a nomination or put my policy in trust?',
    content:
      `<p>When you take out a policy we'll ask you who you want your payout to go to.</p>
      <h5><b>Making a nomination</b></h5>
      <p>Because we're a friendly society, you can make a nomination. This means you can name a person or people to receive up to £5,000 of your payout. They'll just need to complete the claim form and send us the death certificate, and we will pay them as quickly as possible. If the payout is more than £5,000, we'll pay the rest in line with our death claims process which may take a little longer.</p>
      <p>We'll let the person claiming know if we need to see other documents to claim the rest of the money.</p>
      <p>£5,000 is the nomination limit across all policies you hold with National Friendly.</p>
      <p>If you want to make a nomination, you'll need to complete and return a nomination form. We'll send you one in your welcome pack if you take out a policy or you can visit our Existing Members page to download one.</p>
      <h5><b>Placing your policy in trust</b></h5>
      <p>This will make sure that the payout goes to those you want to get the money and may have inheritance tax benefits. You'll need to check that any trust you set up allows you to choose the people you want to leave your money to.</p>
      <p>If you want to put your policy in trust, you'll need to complete and return a trust form. You may want to get help from a solicitor to do this. You can find out more on our Existing Members page.</p>`,
  },
  {
    heading: 'What other benefits do I get with an Over 50s Life Cover policy?',
    content:
      `<p>Your policy comes with free access to our virtual GP service. It’s called Friendly GP and it can be accessed over the phone or online 24 hours a day, 7 days a week.</p>
      <p>We’ll give you the full details when you take out a policy or you can go to our Existing Members page to find out more.</p>`,
  },
  {
    heading: 'How do I claim on my policy?',
    content:
      `<p>If you are looking to make a claim, please head over to our Claims page. There you will find more information on how to contact us and how we can help guide you through the process.</p>`,
  },
];
